/**
 * @file
 * Theme styling
 *
 * These our the styles ported over from the Drupal 6 theme.
 */

/**
 * @file
 * Zen's rollover edit links for blocks.
 */


div.block.with-block-editing {
  position: relative;
}

div.block.with-block-editing div.edit {
  display: none;
  position: absolute;
  right: 0; /* LTR */
  top: 0;
  z-index: 40;
  border: 1px solid #eee;
  padding: 0 2px;
  font-size: 0.75em;
  background-color: #fff;
}

div.block.with-block-editing:hover div.edit {
  display: block;
}


/**
 * @file
 * Block Styling
 */
.region-header ul.menu,
.region-header-right ul.menu{
  list-style:none;
  list-style-image:none;
  margin:0;
  padding:0;
}

.region-header #block-menu-menu-top-left-menu ul.menu li{
  margin-left:0;
}

.region-header-right ul.menu li a:hover{
  background-position:-28px 0;
}

.region-header-right ul.menu li a.twitter{
  background-position:0 -28px;
}

.region-header-right ul.menu li a.twitter:hover{
  background-position:-28px -28px;
}

.region-header-right ul.menu li a.pinterest{
  background-position:0 -56px;
}

.region-header-right ul.menu li a.pinterest:hover{
  background-position:-28px -56px;
}


.region-pre-footer {
  margin-top:-100px;
  margin-bottom:150px;
  margin-left:60px;
  height:27px;
}
.region-pre-footer ul.menu{
  list-style:none;
  list-style-image:none;
  margin:0;
  padding:0;
}
.region-pre-footer ul.menu li {
  list-style:none;
  list-style-image:none;
  float:left;
  margin-right:10px;
  background:#a39c5b;
  height:27px;
  line-height:27px;
  padding:0 10px;
}
.region-pre-footer ul.menu li a{
  text-decoration:none;
  color:#fff;
}
.region-pre-footer ul.menu li.themed-menu-1308
/*.region-pre-footer ul.menu li.last*/
{
  background:#a39c5b url(../images/facebook.gif) top right no-repeat;
  padding-right:40px;
  overflow:hidden;
  margin-right:0;
}
.region-pre-footer ul.menu li.themed-menu-1308 a{
  display:block;
  overflow:hidden;
  width:155px;
}
.region-pre-footer ul.menu li.themed-menu-5859 {
  padding:0;
}
.region-pre-footer ul.menu li.themed-menu-5859 a {
  background:url(../images/twitter_button.png) top right no-repeat;
  display: block;
  width:90px;
  height:27px;
  text-indent: -9999px;
  overflow: hidden;

}
.block /* Block wrapper */ {
  margin-bottom: 1em;
}

#block-albertwisner_tweaks-0 h2.title {
  display:none;  
}


/**
 * @file
 * Comment Styling
 */
#comments /* Wrapper for the list of comments and its title */ {
  margin: 1em 0;
}


/**
 * @file
 * Form Styling
 */


.form-item,
.form-checkboxes,
.form-radios /* Wrapper for a form element (or group of form elements) and its label */ {
  margin: 1em 0;
}

.form-item input.error,
.form-item textarea.error,
.form-item select.error /* Highlight the form elements that caused a form submission error */ {
  border: 2px solid #c00;
}

.form-item label /* The label for a form element */ {
  display: block;
  font-weight: bold;
}

.form-item label.option /* The label for a radio button or checkbox */ {
  display: inline;
  font-weight: normal;
}

.form-required /* The part of the label that indicates a required field */ {
  color: #c00;
}

.form-item .description /* The descriptive help text (separate from the label) */ {
  font-size: 0.85em;
}

.form-checkboxes .form-item,
.form-radios .form-item /* Pack groups of checkboxes and radio buttons closer together */ {
  margin: 0.4em 0;
}

.form-submit /* The submit button */ {
}

.container-inline div,
.container-inline label /* Inline labels and form divs */ {
  display: inline;
}

.tips /* Tips for Drupal's input formats */ {
}


/*
 * Search (search-theme-form.tpl.php)
 */
#search-box /* Wrapper for the search form */ {
}

#edit-search-theme-form-1-wrapper label /* Label that says "Search this site:" */ {
  display: none;
}


/*
 * Search (search-block-form.tpl.php)
 */
#search-block-form /* Wrapper for the search form */ {
}

#edit-search-block-form-1-wrapper label /* Label that says "Search this site:" */ {
  display: none;
}

#search-library-form {
  position:relative;
}
#search-library-input-submit {
  position: absolute;
  right:0;
  top:0;
  width:45px;
  height:44px;
  z-index: 1;
}
/*
 * Drupal's default login form block
 */
#user-login-form {
  text-align: left; /* LTR */
}


/*
 * OpenID
 *
 * The default styling for the OpenID login link seems to assume Garland's
 * styling of list items.
 */

#user-login-form ul /* OpenID creates a new ul above the login form's links. */ {
  margin-bottom: 0; /* Position OpenID's ul next to the rest of the links. */
}

#user-login-form li.openid-link /* The "Log in using OpenID" link. */ {
  margin-top: 1em;
  margin-left: -20px; /* LTR */ /* Un-do some of the padding on the ul list. */
}

#user-login-form li.user-link /* The "Cancel OpenID login" link. */ {
  margin-top: 1em;
}

#user-login ul {
  margin: 1em 0;
}

#user-login li.openid-link,
#user-login li.user-link /* The OpenID links on the /user form. */ {
  margin-left: -2em; /* LTR */ /* Un-do all of the padding on the ul list. */
}


/*
 * Drupal admin tables
 *
 * We overrode these styles in html-elements.css, but restore them for the admin
 * section of the site.
 */

form tbody {
  border-top: 1px solid #ccc;
}

form th,
form thead th {
  text-align: left; /* LTR */
  padding-right: 1em; /* LTR */
  border-bottom: 3px solid #ccc;
}

form tbody th {
  border-bottom: 1px solid #ccc;
}



/**
 * @file
 * Layout Styling (DIV Positioning)
 *
 * Define CSS classes to create a table-free, 3-column, 2-column, or single
 * column layout depending on whether blocks are enabled in the left or right
 * columns.
 *
 * This layout is based on the Zen Columns layout method.
 *   http://drupal.org/node/201428
 *
 * Only CSS that affects the layout (positioning) of major elements should be
 * listed here.  Such as:
 *   display, position, float, clear, width, height, min-width, min-height
 *   margin, border, padding, overflow
 */


/*
 * Body
 */
* {
  margin: 0;
}


body {
  margin: 0;
  padding: 0;
}


body {
}

#page-wrapper,
#main-menu-wrapper .region,
.region-page-closure,
.wrap {
  //min-width: 970px; /* Don't allow the browser to make the site unreadable. */
}

#page-wrapper,
#main-menu-wrapper .region,
.wrap{
  //max-width: 1230px; /* Don't allow the browser to make the site too big. */
}
#body-wrapper,
#main-menu-wrapper .region,
#footer {
/*#main-menu-wrapper {*/
  //min-width: 1000px; /* page-wrapper width + margin. */
}

/* page-wrapper width + margin. */
/** #main-menu-wrapper .region {
  min-width: 1000px; 
  max-width: 1280px; 
} **/
div.region-header{
  /** min-width:780px; **/
  max-width: 1086px; /* Don't allow the browser to make the site too big. */
}

#page {
  
}

/*
 * Header
 */
#header {
  
}

#header .section {
}

#search-box {
}
/*
 * Main (container for everything else)
 */
#main-wrapper {
}

/*
 * Content
 */
// #content {
//   float: left; /* LTR */
//   width: 100%;
//   margin-left: 0; /* LTR */
//   margin-right: -100%; /* LTR */ /* Negative value of #content's width + left margin. */
//   padding: 0; /* DO NOT CHANGE. Add padding or margin to #content .section. */
// }

// #content .section,
// .no-sidebars #content .section {
//   margin: 0;
//   padding:0;
// }

// .sidebar-first #content .section {
//   padding-left: 420px; /* LTR */ The width + left margin of .region-sidebar-first.
//   padding-right: 0; /* LTR */
// }

// .sidebar-second #content .section {
//   padding-left: 0; /* LTR */
//   padding-right: 307px; /* LTR */ /* The width + right margin of .region-sidebar-second. */
// }

// .two-sidebars #content .section {
//   padding-left: 340px; /* The width + left margin of .region-sidebar-first. */
//   padding-right: 307px; /* The width + right margin of .region-sidebar-second. */
// }

/*
 * Navigation
 */
#navigation {
  float: left; /* LTR */
  width: 100%;
  margin-left: 0; /* LTR */
  margin-right: -100%; /* LTR */ /* Negative value of #navigation's width + left margin. */
  padding: 0; /* DO NOT CHANGE. Add padding or margin to #navigation .section. */
  height: 2.3em; /* The navigation can have any arbritrary height. We picked one
                    that is the line-height plus 1em: 1.3 + 1 = 2.3
                    Set this to the same value as the margin-top below. */
}

.with-navigation #content,
.with-navigation .region-sidebar-first,
.with-navigation .region-sidebar-second {
  //margin-top: 2.3em; /* Set this to the same value as the navigation height above. */
  margin-top:0; /* Set this to the same value as the navigation height above. */
}

#navigation .section {
}

#navigation ul /* Primary and secondary links */ {
  margin: 0;
  padding: 0;
  text-align: left; /* LTR */
}

#navigation li /* A simple method to get navigation links to appear in one line. */ {
  float: left; /* LTR */
  padding: 0 10px 0 0; /* LTR */
}

/*
 * First sidebar
 */
// .region-sidebar-first {
//   float: left; /* LTR */
//   width: 340px;
//   margin-left: 0; /* LTR */
//   margin-right: -340px; /* LTR */ /* Negative value of .region-sidebar-first's width + left margin. */
//   padding: 0; /* DO NOT CHANGE. Add padding or margin to .region-sidebar-first .section. */
// }

// .region-sidebar-first .section {
//   margin: 0 20px 0 20px; /* LTR */
//   padding: 0;
// }

// .sidebar-first .region-sidebar-first {
//   width:420px;
// }
// .sidebar-first .region-sidebar-first .section{
//   margin: 0 30px 0 30px; /* LTR */
//   padding: 0;
// }
// /*
//  * Second sidebar
//  */
// .region-sidebar-second {
//   float: right; /* LTR */
//   width: 307px;
//   margin-left: -307px; /* LTR */ /* Negative value of .region-sidebar-second's width + right margin. */
//   margin-right: 0; /* LTR */
//   padding: 0; /* DO NOT CHANGE. Add padding or margin to .region-sidebar-second .section. */
// }

// .region-sidebar-second .section {
//   margin: 0 0 0 20px; /* LTR */
//   padding: 0;
// }

/*
 * Footer
 */
#footer {
}
#footer .section {
  
}

#footer .region-footer {
  
}
#footer .region-footer a{
  
}
#footer .region-footer a:hover,
#footer .region-footer a:visited {
}
#footer .region-footer p{

}
/*
 * Closure
 */
.region-page-closure /* See also the #page-wrapper declaration above that this div shares. */ {
}

/*
 * Prevent overflowing content
 */
#header,
#content,
#navigation,
.region-sidebar-first,
.region-sidebar-second,
#footer,
.region-page-closure {
  overflow: visible;
  word-wrap: break-word; /* A very nice CSS3 property */
}

#navigation {
  overflow: hidden; /* May need to be removed if using a dynamic drop-down menu */
}

/*
 * If a div.clearfix doesn't have any content after it and its bottom edge
 * touches the bottom of the viewport, Firefox and Safari will mistakenly
 * place several pixels worth of space between the bottom of the div and the
 * bottom of the viewport. Uncomment this CSS property to fix this.
 * Note: with some over-large content, this property might cause scrollbars
 * to appear on the #page-wrapper div.
 */
/*
#page-wrapper {
  overflow-y: hidden;
}
*/


/**
 * @file
 * Message Styling
 *
 * Sensible styling for Drupal's error/warning/status messages.
 */


div.messages,
div.status,
div.warning,
div.error /* Important messages (status, warning, and error) for the user */ {
  min-height: 21px;
  margin: 0 1em 5px 1em;
  border: 2px solid #ff7;
  padding: 5px 5px 5px 35px; /* LTR */
  color: #000;
  background-color: #ffc;
  background-image: url(../images/messages-status.png);
  background-repeat: no-repeat;
  background-position: 5px 5px; /* LTR */
}

div.status /* Normal priority messages */ {
}

div.warning /* Medium priority messages */ {
  border-color: #fc0;
  background-image: url(../images/messages-warning.png);
}

div.warning,
tr.warning {
  color: #000; /* Drupal core uses #220 */
  background-color: #ffc;
}

div.error /* High priority messages. See also the .error declaration in pages.css. */ {
  /* border: 1px solid #d77; */ /* Drupal core uses: 1px solid #d77 */
  border-color: #c00;
  background-image: url(../images/messages-error.png);
}

div.error,
tr.error {
  color: #900; /* Drupal core uses #200 */
  background-color: #fee;
}

div.messages ul {
  margin-top: 0;
  margin-bottom: 0;
}



/**
 * @file
 * Node Styling
 *
 * Style anything that isn't in the $content variable.
 */

.node-unpublished div.unpublished,
.comment-unpublished div.unpublished /* The word "Unpublished" displayed underneath the content. */ {
  height: 0;
  overflow: visible;
  color: #d8d8d8;
  font-size: 75px;
  line-height: 1;
  font-family: Impact, "Arial Narrow", Helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  word-wrap: break-word; /* A very nice CSS3 property */
}


.marker /* "New" or "Updated" marker for content that is new or updated for the current user */ {
  color: #c00;
}


.node.node-unpublished .picture,
.comment.comment-unpublished .picture {
  position: relative; /* Otherwise floated pictures will appear below the "Unpublished" text. */
}


.node .field {
  margin-top:10px;
}


/**
 * @file
 * Page Background Styling
 *
 * The default layout method of Zen doesn't give themers equal-height columns.
 * However, equal-height columns are difficult to achieve and totally
 * unnecessary. Instead, use the Faux Columns method described in the following
 * ALA article:
 *   http://www.alistapart.com/articles/fauxcolumns/
 */


body {
  background:$white;
}

#header {
}

#header .section {
}

#main-wrapper {
}
.two-sidebars #main,
.sidebar-second #main {
  //background:url(../images/black_pixel.gif) 100% 0px repeat-y;
}
.two-sidebars #content-wrapper,
.sidebar-second #content-wrapper {
  //background:url(../images/black_pixel.gif) 340px 0px repeat-y;
}
.sidebar-first #content-wrapper{
 // background:url(../images/black_pixel.gif) 420px 0px repeat-y;
}

/* $Id$ */ /**
 * @file
 * Page Styling
 *
 * Style the markup found in page.tpl.php. Also includes some styling of
 * miscellaneous Drupal elements that appear in the $content variable, such as
 * ul.links, .pager, .more-link, etc.
 */ /*
 * Body
 */
body {
  margin: 0;
  padding: 0;
}

#page-wrapper {
}

#page {
  
}
div#admin-toolbar.vertical{
  z-index:10000;
}

#page, #skip-link{
  font-size: 0.75em;
  line-height: 1.333em;
  font-size: 0.875em;
  line-height: 1.286em;
}

/*
 * The skip navigation link will be completely hidden until a user tabs to the
 * link. See http://www.webaim.org/techniques/skipnav/
 */
#skip-link a,#skip-link a:visited {
  position: absolute;
  display: block;
  left: 0;
  top: -500px;
  width: 1px;
  height: 1px;
  overflow: hidden;
  text-align: center;
  background-color: #666;
  color: #fff;
}

#skip-link a:hover,#skip-link a:active,#skip-link a:focus {
  position: static;
  width: 100%;
  height: auto;
  padding: 2px 0 3px 0;
}

/*
 * Header
 */
#header {
  
}

#header .section {
  
}

#name-and-slogan /* Wrapper for website name and slogan */ {
  
}

h1#site-name,div#site-name /* The name of the website */ {
  margin: 0;
  font-size: 2em;
  line-height: 1.3em;
}

#site-name a:link,#site-name a:visited {
  color: #000;
  text-decoration: none;
}

#site-name a:hover,#site-name a:focus {
  text-decoration: underline;
}

#site-slogan /* The slogan (or tagline) of a website */ {
  
}

.region-header /* Wrapper for any blocks placed in the header region */ {
  position: relative;
}

// .region-header #block-menu-menu-top-left-menu ul li a{
//   display:inline-block;
//   height:27px;
//   line-height:27px;
//   padding:0 10px;
// margin:0 5px 0 0;
// background:#a69f5c;
// color:#fff;
// text-decoration:none;
// }

.region-header #block-menu-menu-top-left-menu ul li a:hover{
  background:#797441;
}


.region-header .block p{
  margin:0;
}

// .region-top #block-block-5.block {
//   float:none;
//   background:transparent none;
//   font-size:0.8em;
//   position:absolute;
//   right:10px;
//   top:75px;
//   text-align:right;
//   height:auto;
// }
.region-top #block-block-5.block p{
  margin:1em 0;
  line-height: 1.1em;
}
.region-top #block-block-5.block a{
  color:#000;
  text-decoration:none;
}



// #block-text-resize-0 .changer {
//   float:left;
//   width:25px;
//   background:transparent none;
//   text-indent:0;
//   margin:0;
//   height:27px;
//   line-height:27px;
//   text-align:center;
//   vertical-align: text-bottom;
//   text-decoration:none;
//   color:#fff;
// }
// #block-text-resize-0 .changer:hover {
//   background:#f2ae0e;
// }
// #block-text-resize-0 sup {
//   display:none;
// }
// #block-text-resize-0 #text_resize_reset {
//   height:25px;
//   padding-top:2px;
//   font-size:1em;
// }

.region-header h2.title,
.region-header h2.block-title{
  float:left;
  margin-right:20px;
  font-size:18px;
  font-weight: normal;
  line-height:25px;
  color:#fff;
}
.region-header a.header-link {
  font-size:18px;
  color:#fff;
  text-decoration:none;
}
.region-header a.header-link.library-link {
  background:url(../images/library_link.gif) right center no-repeat;
  display: block;
  float:left;
  height:27px;
  padding-right:50px;
  margin-right:-10px;
}
.region-header .content{
  vertical-align: text-bottom;
  float:left;
}

/**
 * My library account link
 */
div.region-header #block-block-2 {
  
}
/*
 * Main (container for everything else)
 */

#main {
}

/*
 * Content
 */
#content {
  
}

#mission /* The mission statement of the site (displayed on homepage) */
  {
  
}

.region-content-top
  /* Wrapper for any blocks placed in the "content top" region */ {
  
}


.front h1.title { /* The title of the front page */ 
    display:none;
}
tr.even /* Some tables have rows marked even or odd. */ {
  /* background-color: #eee; */ /* Drupal core uses a #eee background */
  
}

tr.odd { /* background-color: #eee; */
  /* Drupal core uses a #eee background */
  
}

div.messages
  /* Important messages (status, warning, and error) for the user. See also the declarations in messages.css. */
  {
  
}

div.status /* Normal priority messages */ {
  
}

div.warning,tr.warning /* Medium priority messages */ {
  /* border: 1px solid #f0c020; */
  /* Drupal core uses: 1px solid #f0c020 */
  
}

div.error,tr.error
  /* High priority messages. See also the .error declaration below. */ {
  
}

.error /* Errors that are separate from div.messages status messages. */
  { /* color: #e55; */ /* Drupal core uses a #e55 background */
  
}

.warning
  /* Warnings that are separate from div.messages status messages. */ {
  /* color: #e09010; */ /* Drupal core uses a #e09010 background */
  
}

div.tabs /* See also the tabs.css file. */ {
  
}

.help /* Help text on a page */ {
  margin: 1em 0;
}

.more-help-link /* Link to more help */ {
  font-size: 1.2em;
  text-align: right;
}

#content-area /* Wrapper for the actual page content */ {
  
}

ul.links /* List of links */ {
  margin: 1em 0;
  padding: 0;
}

ul.links.inline {
  margin: 0;
  display: inline;
}

ul.links li {
  display: inline;
  list-style-type: none;
  padding: 0 0.5em;
}

.pager
  /* A list of page numbers when more than 1 page of content is available */
  {
  clear: both;
  margin: 1em 0;
  text-align: center;
}

.pager a,.pager strong.pager-current
  /* Each page number in the pager list */ {
  padding: 0.5em;
}

.feed-icons
  /* The links to the RSS or Atom feeds for the current list of content */
  {
  margin: 1em 0;
}

.more-link /* Aggregator, blog, and forum more link */ {
  text-align: right; /* LTR */
}

.region-content-bottom
  /* Wrapper for any blocks placed in the "content bottom" region */ {
  
}

/*
 * First sidebar (on left in LTR languages, on right in RTL)
 *
 * Remember to NOT add padding or margin to your .region-sidebar-first
 * (see the layout.css file.)
 */


#search-library-input-q-wrapper label {
  line-height:45px;
  font-size:22px;
  font-family: "Palatino Linotype","Book Antiqua",Palatino,FreeSerif,serif;
  color:#a6a6a6;
  font-weight: normal;
}
#search-library-input-q {
  width:240px;
  height:45px;
  line-height:45px;
  font-size:22px;
  font-family: "Palatino Linotype","Book Antiqua",Palatino,FreeSerif,serif;
  color:#a6a6a6;
  font-weight: normal;
  background:transparent none;
  border: 0 none;
  padding:0 10px;
  margin:0;
}
.sidebar-first #search-library-input-q {
  width:300px;
}
#search-library-input-submit {
  width:40px;
  height:45px;
  border:0 none;
  background:transparent none;
  padding:0;
  margin:0;
  text-indent:-100px;
  text-transform:capitalize;
}
#search-library-form h2{
  display:none;
}

#block-views-site_images-block_1 ul {
  list-style:none;
  list-style-image:none;
  width:300px;
  height:230px;
  overflow:hidden;
  margin:0;
  padding:0;
}


/*
 * Second sidebar (on right in LTR languages, on left in RTL)
 *
 * Remember to NOT add padding or margin to your .region-sidebar-second
 * (see the layout.css file.)
 */
.region-sidebar-second {
  //border-left:1px solid #141414;
}

/*
 * Footer
 */
#footer {  
}

#footer .section {
  
}

#footer-message
  /* Wrapper for the footer message from Drupal's "Site information"
                   and for any blocks placed in the footer region */ {
  
}

.region-footer {
  
}

/*
 * Closure
 */
.region-page-closure
  /* Wrapper for any blocks placed in the closure region */ {
  
}

/*
 * Drupal boxes
 *
 * Wrapper for Comment form, Comment viewing options, Menu admin, and
 * Search results.
 */
.box /* Wrapper for box */ {
  
}

.box h2 /* Box title */ {
  
}

.box .content /* Box's content wrapper */ {
  
}

/*
 * Markup free clearing (See: http://www.positioniseverything.net/easyclearing.html )
 */
.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

/**
 * Hide elements from all users.
 *
 * Used for elements which should not be immediately displayed to any user. An
 * example would be a collapsible fieldset that will be expanded with a click
 * from a user. The effect of this class can be toggled with the jQuery show()
 * and hide() functions.
 */
.element-hidden {
  display: none;
}

/**
 * Hide elements visually, but keep them available for screen-readers.
 *
 * Used for information required for screen-reader users to understand and use
 * the site where visual display is undesirable. Information provided in this
 * manner should be kept concise, to avoid unnecessary burden on the user. Must
 * not be used for focusable elements (such as links and form elements) as this
 * causes issues for keyboard only or voice recognition users. "!important" is
 * used to prevent unintentional overrides.
 */
.element-invisible {
  position: absolute !important;
  clip: rect(1px, 1px, 1px, 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
}
/*
 * Font formats
 * use em for all font sizes in order to allow easy text resizing from javascript.
 */

 div#admin-menu {
   z-index:9999;
 }
 
 div#ui-datepicker-div {
   background:#fff;
 }


 /**
 * @file
 * Tabs Styling
 *
 * Adds styles for the primary and secondary tabs.
 *
 * Compare this with default CSS found in the system module's stylesheet (a copy
 * of which is in drupal6-reference.css, line 510.)
 */


div.tabs {
  margin: 0 0 5px 0;
}

ul.primary {
  margin: 0;
  padding: 0 0 0 10px; /* LTR */
  border-width: 0;
  list-style: none;
  white-space: nowrap;
  line-height: normal;
  background: url(../images/tab-bar.png) repeat-x left bottom;
}

ul.primary li {
  float: left; /* LTR */
  margin: 0 4px 0 0;
  padding: 0;
}

ul.primary li a {
  display: block;
  height: 24px;
  margin: 0;
  padding: 0 4px 0; /* width of tab-left.png */
  border-width: 0;
  font-weight: bold;
  text-decoration: none;
  color: #777;
  background-color: transparent;
}

ul.primary li a .tab {
  display: block;
  height: 20px; /* 24px (parent) - 4px (padding) */
  margin: 0;
  padding: 4px 13px 0 6px;
  border-width: 0;
  line-height: 20px;
}

// ul.primary li a:hover {
//   border-width: 0;
//   background-color: transparent;
//   background: url(../images/tab-left.png) no-repeat left -76px;
// }

// ul.primary li a:hover .tab {
//   background: url(../images/tab-right.png) no-repeat right -76px;
// }

// ul.primary li.active a,
// ul.primary li.active a:hover {
//   border-width: 0;
//   color: #000;
//   background-color: transparent;
//   background: url(../images/tab-left.png) no-repeat left 0;
// }

// ul.primary li.active a .tab,
// ul.primary li.active a:hover .tab {
//   background: url(../images/tab-right.png) no-repeat right 0;
// }

// ul.secondary {
//   margin: 0;
//   padding: 0 0 0 5px; /* LTR */
//   border-bottom: 1px solid #c0c0c0;
//   list-style: none;
//   white-space: nowrap;
//   background: url(../images/tab-secondary-bg.png) repeat-x left bottom;
// }

// ul.secondary li {
//   float: left; /* LTR */
//   margin: 0 5px 0 0;
//   padding: 5px 0;
//   border-right: none; /* LTR */
// }

// ul.secondary a {
//   display: block;
//   height: 24px;
//   margin: 0;
//   padding: 0;
//   border: 1px solid #c0c0c0;
//   text-decoration: none;
//   color: #777;
//   background: url(../images/tab-secondary.png) repeat-x left -56px;
// }

// ul.secondary a .tab {
//   display: block;
//   height: 18px; /* 24px (parent) - 6px (padding) */
//   margin: 0;
//   padding: 3px 8px;
//   line-height: 18px;
// }

// ul.secondary a:hover {
//   background: url(../images/tab-secondary.png) repeat-x left bottom;
// }

// ul.secondary a.active,
// ul.secondary a.active:hover {
//   border: 1px solid #c0c0c0;
//   color: #000;
//   background: url(../images/tab-secondary.png) repeat-x left top;
// }


/* $Id$ */ /**
 * @file
 * Views Styling
 */

.region-sidebar-first .view-site-images ul ,
.region-sidebar-second .view-site-images ul {
  list-style: none;
  list-style-image: none;
  padding: 0;
  margin: 0;
}

.not-front .region-sidebar-first .view-site-images .views-field-image-image .field-content{
  display:block;
  height:140px;
}
  .view-display-id-block_3 {
            font-size:1em;
          }

#block-views-76b315d2c02621aaf053f89af366a802,
#block-views-calendar-calendar_block_1{
  width:175px;
  float:left;
  font-size:0.65em;
}
#block-views-calendar-calendar_block_1{
  margin-right:10px;
}

.calendar-calendar div.date-nav {
  background-color:#a39f78;
  color:#fff;
}
.calendar-calendar div.date-nav a{
  color:#fff;
  text-decoration:none;
}
.calendar-calendar th.days {
  background-color:#e9e5c5;
  border:1px solid #d9d7b6;
  color:#989a75;
  font-size:.7em;
}
.calendar-calendar td a {
  color:$dark-gray;
  font-size:.7em;
}
.calendar-calendar td .inner div.calendar div a {
  border-bottom: 1px solid #98a075;
}
.calendar-calendar td .inner div.calendar div a:hover {
  border-bottom: 1px dashed #98a075;
}
.calendar-calendar td a:hover,
table .mini-day-on a  {
  text-decoration:none;
  border-bottom:1px solid #993333;
}



.views-upcoming-events .item-list h3{
  font-size:1em;
  text-transform: none;
  margin-top:10px;
  background:url(../images/bullet_02.gif) left center no-repeat;
  padding-left: 16px;
}
.views-upcoming-events .item-list h3 {
  font-size:1.2em;
}


.views-upcoming-events .item-list ul,
#panel-front-page .views-home-events .item-list ul {
  list-style:none;
  list-style-image:none;
  margin:10px 0px 10px 20px;
  padding:0;
}

#panel-front-page .views-home-events .item-list ul{
  margin-left:0px;
}

.views-home-events .views-row {
  margin-bottom: 10px;
}
.views-home-events .field-content {
/*  display:block;*/
  float:none;
  width:auto;
}
.views-home-events .field-content .date-display-start ,
.views-home-events .field-content .date-display-end {
}
.views-home-events .field-content .date-display-separator {
}

.views-home-events .views-field-title {
/*  border-left:1px solid #444444;*/
/*  padding-left:10px;*/
}
.views-home-events .views-field-title a{
  color:#000;
/*  text-decoration:none;*/
}
.views-upcoming-events .views-row {
  margin-top:5px;
    margin-bottom:20px;
}
.views-upcoming-events .views-field-title {
  margin-bottom:5px;
}
.views-upcoming-events .views-field-title a{
  color:#000;
  text-decoration:none;
  font-size:1.2em;
}
.views-upcoming-events .views-field-field-ts-event-date-1 {
  color:#49483f;
  float:left;
  width:100px;
  margin-right:-100px;
}
.views-upcoming-events .views-field-field-ts-event-date-1 .field-content{
  padding-left:0;
}
.views-upcoming-events .views-field-title,
.views-upcoming-events .views-field-field-ts-event-contact-email,
.views-upcoming-events .views-field-field-ts-event-contact-text,
.views-upcoming-events .views-field-field-event-room-nid,
.views-upcoming-events .views-field-field-event-room,
.views-upcoming-events .views-field-field-ts-event-age-group,
.views-upcoming-events .views-field-field-ts-event-program-type,
.views-upcoming-events .views-field-field-ts-event-registration-req,
.views-upcoming-events .views-field-field-ts-event-registration-note,
.views-upcoming-events .views-field-nothing,
.views-upcoming-events .views-field-field-registration-message,
/*.views-upcoming-events .views-field-field-ts-event-registration-note-value,*/
.views-upcoming-events .views-field-body
{
  padding-left:100px;
}
.views-upcoming-events .views-field-field-event-room-nid .field-content {
  display:inline;
}
.views-upcoming-events .views-field-nothing {
  float:left;
  margin-right: 1em;
}
.views-field-field-ts-event-age-group .field-content {
  display: inline;
}

#views-exposed-form-ts-events-calendar-page-1 .container-inline-date {
 // width:245px;
  width:100%;
}
#edit-date-filter-value-wrapper {
  position:relative;
}
#views-exposed-form-ts-events-calendar-page-1 .date-year {
  //position:absolute;
  right:0;
}

#views-exposed-form-ts-events-calendar-page-1 .container-inline-date select{
  width:75px;
}

.views-field-field-ts-event-registration-note-value.ui-dialog-content {
  padding:0;
  font-size:13px;
}
.views-field-field-ts-event-registration-note-value.ui-dialog-content .field-content {
  padding:10px;
}
/**
 * jQuery UI
 */
.ui-dialog-titlebar {
  font-size:0.8em;
  padding-top:5px;
  padding-bottom:5px;
}

/* Book Lovers and Movie Lovers */
.view-online-book-club .views-row {
  clear:both;
  width:100%;
  height:80px;
  display: table-row;
  vertical-align: middle;
}
.view-online-book-club .views-field-image-attach-images {
  float:left;
  margin-right:10px;
}
.view-online-book-club .views-field-title {
  min-height: 70px;
  display: table-cell;
  vertical-align: middle;
}
.view-online-book-club .views-field-title .field-content {
}
.view-online-book-club .views-field-title .field-content a {
}



body.emvideo-modal-iframe {
  overflow:hidden;
  background:#fff;
  margin:0;
  padding:0;
}
body.emvideo-modal-iframe *{
  width:100%;
  height:100%;
  margin:0;
  padding:0;
}


/*Event calendar*/
.view-event-rooms .form-checkboxes,
.view-event-rooms .views-widget {
  /*overflow: hidden;*/
  margin:0;
}
.view-event-rooms .bef-toggle  {
  /*float:left;*/
  line-height: 20px;
  /*display: block;*/
  padding: 0;
  margin-right:20px;
}
.view-event-rooms .bef-checkboxes {
  /*float:left;*/
  line-height: 20px;
  /*display: block;*/
  padding: 0;
}
.view-event-rooms .bef-checkboxes .form-item {
  float:left;
  margin:0 20px 0 0;
  line-height: 20px;
  padding: 0;
  width:230px;
}
.view-event-rooms .views-exposed-form .views-exposed-widget {
  float:none;
  clear:left;
}
.view-event-rooms .views-exposed-widget .form-submit {
  margin-top:12px;
}
.views-extra-checkbox-widget {
  float:left;
}
 
.views-extra-checkbox-widget label{
  font-weight: normal;
}
#edit-submit-event-rooms {
  clear:left;
}
.rooms-calendar-links-section ul {
  width:96%;
  position: relative;
  list-style: none;
  list-style-image: none;
  margin:0 auto;
}
.rooms-calendar-links-section ul li {
  float:left;
  margin-left:100%;
  position:relative;
  right:55%;
}
.rooms-calendar-links-section ul li.last {
  float:none;
  margin:0;
  position: absolute;
  right:0;
}
.rooms-calendar-links-section ul li.first {
  margin:0;
  float:none;
  position: absolute;
  left:0;
  right: auto;
}

/**
 * @file
 * Wireframes Styling
 *
 * Add wireframes to the basic layout elements.
 */


.with-wireframes #header .section,
.with-wireframes #content .section,
.with-wireframes #navigation .section,
.with-wireframes .region-sidebar-first .section,
.with-wireframes .region-sidebar-second .section,
.with-wireframes #footer .section {
  margin: 1px;
  padding: 2px;
  border: 1px solid #ccc;
}

.with-wireframes .region-page-closure {
  margin-top: 1px;
  padding: 2px;
  border: 1px solid #ccc;
}

