// @file
// Custom sass mixins
//
// Define the custom mixins for your project here.
// http://sass-lang.com/docs/yardoc/file.SASS_REFERENCE.html#defining_a_mixin

//Screen Size Presets (this can be adjusted or added to if necessary)
//USAGE: The below example checks for anything lower than 768px and hides the #hero div with a display:none property
// #hero{
//  @include breakpoint($screen-md-min){display:none} 
// }
//

//sass breakpoint idea from this article: https://www.sitepoint.com/managing-responsive-breakpoints-sass/

$breakpoints: (
  'xsmall' : 480,
  'small'  : 768,
  'medium' : 992,
  'large'  : 1200,
  'xlarge' : 1350,
  'xxlarge' : 1450,
  'gform-mobile': 640, //special size for gfrom mobile
);

@mixin respond-to($breakpoint) {
  // Retrieves the value from the key
  $value: map-get($breakpoints, $breakpoint);

  // If the key exists in the map
  @if $value != null {
    // Prints a media query based on the value
    @media (min-width: $value + px) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}


@mixin respond-to-max($breakpoint) {
  // Retrieves the value from the key
  $value: map-get($breakpoints, $breakpoint);

  // If the key exists in the map
  @if $value != null {
    // Prints a media query based on the value
    @media (max-width: ($value - 1) + px) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}



// Makes an element visually hidden, but accessible.
// @see http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
@mixin element-invisible {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

// Turns off the element-invisible effect.
@mixin element-invisible-off {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: auto;
}

// Makes an element visually hidden by default, but visible when focused.
@mixin element-focusable {
  @include element-invisible;

  &:active,
  &:focus {
    @include element-invisible-off;
  }
}

/*********************
HTML LIST ITEM WITH NOT LIST STYLE 
removes the list-style settings from an HTML list
USAGE:  @include listNoStyle
*********************/

@mixin listNoStyle{
  list-style-type:none;
  margin:0;
  padding:0;  
  display:inline-block;
  
  li{
    display: inline-block;
    list-style-type:none;
  }
  
}

/*********************
OPACITY
adds an opacity to an HTML element
USAGE:  @include opacity(.8,80);
*********************/
@mixin opacity($perc:1.0, $percfull:100){
  zoom: 1;
  filter: alpha(opacity=$percfull);
  opacity: $perc;
}

/* @include css-gradient(#dfdfdf,#f8f8f8); */
@mixin css-gradient($from: #dfdfdf, $to: #f8f8f8) {
  background-color: $from;
  background-image: -webkit-gradient(linear, left top, left bottom, from($from), to($to));
  background-image: -webkit-linear-gradient(top, $from, $to);
  background-image: -moz-linear-gradient(top, $from, $to);
  background-image: -o-linear-gradient(top, $from, $to);
  background-image: linear-gradient(to bottom, $from, $to); 
  background: -ms-linear-gradient(left, $from ,$to);
  filter: progid:DXImageTransform.Microsoft.Gradient(GradientType=0, startColorstr='#{$from}', endColorstr='#{$to}');
  -ms-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$from}', endColorstr='#{$to}',GradientType=0);
}

/* @include css-gradient-two-horizontal(#dfdfdf,0%, #f8f8f8, 80%); */
@mixin css-gradient-two-horizontal($from: #dfdfdf, $lperc:0%, $to: #f8f8f8, $rtperc:100%) {
  background: $to;
  background: -webkit-gradient(linear, left top, left bottom, from($lperc,$from), to($rtperc,$to));
  background: -webkit-linear-gradient(left, $from $lperc, $to $rtperc);
  background: -moz-linear-gradient(left, $from $lperc, $to $rtperc);
  background: -o-linear-gradient(left, $from $lperc, $to $rtperc);
  background: linear-gradient(to right, $from $lperc, $to $rtperc); 
  background: -ms-linear-gradient(left, $from $lperc ,$to $rtperc);
  filter: progid:DXImageTransform.Microsoft.Gradient(GradientType=1, startColorstr='#{$from}', endColorstr='#{$to}');
  -ms-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$from}', endColorstr='#{$to}' ,GradientType=1);
}

/* @include css-gradient-two-vertical(#dfdfdf,0%, #f8f8f8, 80%); */
@mixin css-gradient-two-vertical($left: #dfdfdf, $lperc:0%, $right: #f8f8f8, $rtperc:100%) {
  background: $left; /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: -moz-linear-gradient(top,  $left $lperc, $right $rtperc); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right bottom, color-stop($lperc,$left), color-stop($rtperc,$right)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  $left $lperc, $right $rtperc); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  $left $lperc, $right $rtperc); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  $left $lperc, $right $rtperc); /* IE10+ */
  background: linear-gradient(to bottom,  $left $lperc, $right $rtperc); /* W3C */
  filter: progid:DXImageTransform.Microsoft.Gradient(GradientType=0, startColorstr='#{$left}', endColorstr='#{$right}');
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$left}', endColorstr='#{$right}',GradientType=0 ); /* IE6-8 */
}

/* @include css-gradient-two-vertical(#dfdfdf,0%, #f8f8f8, 80%); */
@mixin css-gradient-two-vertical($left: #dfdfdf, $lperc:0%, $right: #f8f8f8, $rtperc:100%) {
  background: $left; /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: -moz-linear-gradient(top,  $left $lperc, $right $rtperc); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, right bottom, color-stop($lperc,$left), color-stop($rtperc,$right)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top,  $left $lperc, $right $rtperc); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top,  $left $lperc, $right $rtperc); /* Opera 11.10+ */
  background: -ms-linear-gradient(top,  $left $lperc, $right $rtperc); /* IE10+ */
  background: linear-gradient(to bottom,  $left $lperc, $right $rtperc); /* W3C */
  filter: progid:DXImageTransform.Microsoft.Gradient(GradientType=0, startColorstr='#{$left}', endColorstr='#{$right}');
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$left}', endColorstr='#{$right}',GradientType=0 ); /* IE6-8 */
}

/**************************
HEXCODE TO RGBA
***************************/
@mixin hex_to_rgba($color: $teal,$opacity: .5,$type: 'background'){

  $red: red($color);
  $green: green($color);
  $blue: blue($color);

  @if $type == 'background'{
    background-color:rgba($red,$green,$blue,$opacity);
  }
  @else if $type == 'color'{
    color:rgba($red,$green,$blue,$opacity);
  }
  @else if $type == 'border'{
    border-color:rgba($red,$green,$blue,$opacity);
  }
}

/*********************
VENDOR PREFIX
allows us to add one line of code that works accross all the browsers for CCS3 elements
USAGE:  @include vendor-prefix('border-radius', '6px');
*********************/
@mixin vendor-prefix($name, $value) {
  @each $vendor in ('-webkit-', '-moz-', '-ms-', '-o-', '') {
    #{$vendor}#{$name}: #{$value};
  }
}

/*********************
DISPLAY FLEX
*********************/
@mixin display-flex(){
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($options: 0 0 1){
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
}

@mixin cta-button($bckg-color: $dark-blue, $bckg-color-hover: $gold, $color: $white){
    color:$color;
    background-color: $bckg-color;
    padding:10px 20px;
    border-radius: 4px;
    display:inline-block;
    text-decoration: none;
    font-size:1.2em;
    font-weight:600;
    transition:all .2s;

    &:hover{
      background-color:$bckg-color-hover;
    }
}

/*********************
KEYFRAME ANIMATION

animation-name: bouncing-arrow;
animation-duration: .6s;
animation-timing-function: ease-out;
animation-delay: 0;
animation-direction: alternate;
animation-iteration-count: infinite;
animation-fill-mode: none;
animation-play-state: running;

the above can be written like:
@include animation('move-the-object .6s ease-out 0s alternate infinite');
*********************/

@mixin animation($animate...) {
    $max: length($animate);
    $animations: '';
    @for $i from 1 through $max {
        $animations: #{$animations + nth($animate, $i)};
        @if $i < $max {
            $animations: #{$animations + ", "};
        }
    }
    -webkit-animation: $animations;
    -moz-animation: $animations;
    -o-animation: $animations;
    animation: $animations;
}


/*********************
KEYFRAMES
*********************/

@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }
    @-moz-keyframes #{$animationName} {
        @content;
    }
    @-o-keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}


/*********************
KEYFRAME bar wave move
*********************/
@include keyframes(pulsate) {
    0% {
        transform: scale(1);
    }
    100% {
        transform: scale(1.1);
    }
}

@include keyframes(arrow-down) {
    0% {
        opacity:.5;
        transform: translate3d(-50%, 0, 0);
    }
    50% {
        opacity:1;
        transform: translate3d(-50%, 20px, 0);
    }
    100% {
        opacity:.5;
        transform: translate3d(-50%, 0, 0);
    }
}

// @include keyframes(animation-slide-in-up) {
//     0% {
//         opacity: 0;
//         transform: translate3d(0, 30px, 0);
//     }
//     100% {
//         opacity: 1;
//         -webkit-transform: none;
//         transform: none;
//     }
// }

// @include keyframes(animation-slide-in-down) {
//     0% {
//         opacity: 0;
//         transform: translate3d(-50%, -10px, 0);
//     }
//     100% {
//         opacity: 1;
//     }
// }

// @include keyframes(animation-slide-out) {
//     0% {
//         opacity: 0;
//         transform: translate3d(-20px, 0, 0);
//     }
//     100% {
//         opacity: 1;
//     }
// }


/*********************
PLACHOLDER STYLING
*********************/
@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}  
}