/**
 * @file
 * Styles are organized using the SMACSS technique. @see http://smacss.com/book/
 *
 * When you turn on CSS aggregation at admin/config/development/performance, all
 * of these @include files will be combined into a single file.
 */

/* Import Sass mixins, variables, Compass modules, etc. */
@import "init";

/* HTML element (SMACSS base) rules */
@import "normalize";

/* Layout rules */
//@import "layouts/responsive";
//@import "layouts/layout-liquid";

/* Component (SMACSS module) rules */
@import "components/misc";

// Optionally, add your own components here.
@import "print";

/* SMACSS theme rules */
@import "theme";

/* @import "theme-B"; */
html{
	height: auto !important;
}
body{
	font-size:100%;
	
	@include respond-to-max(medium){
		&:before{
			content:'';
			position:absolute;
			z-index: 1000;
			top:0;
			left:0;
			bottom:0;
			right:0;
			display: block;
			width:100%;
			height: auto;
			transition: all .3s;
			//background:$blue url(../images/diagonal-blue.jpg) repeat left top;
			@include css-gradient-two-vertical(lighten($light-blue,40%),0%, $light-blue, 80%);
			//background-color: $dark-blue;
			pointer-events: none;
			@include opacity(0,0);
		}
	}

	p{
		color:#2d2d2d;
	}

	a{
		color:$red;
		transition:all .2s;

		&:hover{
			color:$blue;
		}
	}
	h2{
		font-size:1.5rem;

		@include respond-to(medium){
			font-size:2rem;
		}
	}
	img{
		&.left{
			margin-right:20px;
			float:left;

			@include respond-to(medium){
				margin-right:30px;
			}
		}
		&.left-top{
			margin:20px 20px 0 0;
			float:left;

			@include respond-to(medium){
				margin:30px 30px 0 0;
			}
		}
		&.left-bottom{
			margin:0 20px 20px 0;
			float:left;

			@include respond-to(medium){
				margin:0 30px 30px 0;
			}
		}
		&.left-top-bottom{
			margin:20px 20px 20px 0;
			float:left;

			@include respond-to(medium){
				margin:30px 30px 30px 0;
			}
		}
		&.right{
			margin-left:20px;
			float:right;

			@include respond-to(medium){
				margin-left:30px;
			}
		}
		&.right-top{
			margin:20px 0 0 20px;
			float:right;

			@include respond-to(medium){
				margin:30px 0 0 30px;
			}
		}
		&.right-bottom{
			margin:0 0 20px 20px;
			float:right;

			@include respond-to(medium){
				margin:0 0 30px 30px;
			}
		}
		&.right-top-bottom{
			margin:20px 0 20px 20px;
			float:right;

			@include respond-to(medium){
				margin:30px 0 30px 30px;
			}
		}
		&.top-space{
			margin-top:20px;

			@include respond-to(medium){
				margin-top:30px;
			}
		}
		&.bottom-space{
			margin-bottom:20px;

			@include respond-to(medium){
				margin-bottom:30px;
			}
		}
	}
}


.cke_contents{
	padding:20px;
}

/* CUSTOMIZE THE BOOTSTRAP GRID **/
// .container{
// 	@include respond-to(xlarge){
// 		width:1260px; 


// 		.row{
// 			@include respond-to(medium){
// 				//@include display-flex();
// 				//align-items: flex-start;
// 			}

// 			&.no-gutters{
// 	 		 	> [class^="col-"],
// 	 		 	> [class*=" col-"]{
// 	 		 		padding-right: 0;
// 	  				padding-left: 0;
// 	 		 	}
// 			}
// 		}
// 	}
// 	.row{
// 		@include respond-to(medium){
// 			@include display-flex();
// 		}

// 		&.no-space-mobile,
// 		&.no-space-mobile > .view-content{
//  		 	@include respond-to-max(small){
//  		 		> [class^="col-"],
// 	 		 	> [class*=" col-"]{
// 	 		 		padding-right: 0;
// 	  				padding-left: 0;
					
// 					@include respond-to(medium){
// 						padding-left:0px;
// 						padding-right:0px;
// 					}
// 	 		 	}
//  		 	}
// 		}
// 	}
// }


/**
 *
 * Site Wide Message Bar
 *
 */
 #site-wide-message-bar{
 	color:$bright-red;
 	text-align:center;
 	font-weight: 700;
 	position: relative;
 	z-index: 2;
 	background-image:url(../images/alert-paper-fold-right.jpg);
 	background-color: $white;
 	background-repeat: no-repeat;
 	background-size:50px auto;
 	background-position:top right;
 	padding:20px 0px;
 	line-height: 1.4em;

	@include respond-to(small) {
 		background-image:url(../images/alert-paper-fold-left.jpg);
 		background-position:top left;
 	}
 	
 	.site-wide-message{
 		padding:0 40px;

 		@include respond-to(small) {
 			padding:0 80px;
 		}
 		@include respond-to(medium) {
 			padding:0 40px;
 		}
 		@include respond-to(large) {
 			padding:0px;
 		}

 		.block {
 			&:last-child{
 				margin-bottom:0px;
 			}
 			p{
 				color:$dark-blue;

 				&:last-child{
	 				margin-bottom:0px;
	 			}
	 		}

	 		a{
	 			color:$bright-red;
	 			transition:all .2s ease-in-out;
	 			text-decoration: none;

	 			&:hover{
	 				color:$light-blue;
	 				text-decoration: none;
	 			}
	 		}
 		}
 	}

 	
 }


/**
 *
 * Custom Mobile Menu
 *
 */
.sf-accordion-toggle{
	color:$white;
	position:fixed;
	z-index:10001;
	top:4px;
	left:2px;
	width:46px;
	height:46px;
	border-radius: 200%;
	@include hex_to_rgba($dark-blue,.8,'background');

	@include respond-to(medium){
		display:none;
	}

	a{
		color:$white;
		border:0px;
		text-decoration:none;
		outline: none;
		position:absolute;
		top:16px;
		left:8px;
		height:14px;
		width:30px;
		display: inline-block;
		z-index: 10000;

		span{
			text-indent:-999px;
			overflow: hidden;
		}

		&:before,
		&:after{
			content:'';
		}

		&:before,
		span,
		&:after{
			background:$white;
			width:30px;
			height:2px;
			border-radius: 10px;
			transition:all .2s ease-in-out;
			position:absolute;
			top:0;
			left:50%;
			transform:translateX(-50%);
		}

		span{
			top:6px;
		}

		&:after{
			top:12px;
		}

		&:after{
			font-size:1.5em;
		}

		&.sf-expanded{

			&:before,
			&:after{
				top:6px;
				left:50%;
				width:30px;
				height:2px;
				background:$white;
			}

			&:before{
				transform:translateX(-50%) rotate(45deg);
			}

			&:after{
				transform:translateX(-50%) rotate(-45deg);
			}

			span{
				@include opacity(0,0);
			}
		}
	}
}

/**
 *
 * Top Bar
 *
 */
#top-bar{
	background-image:url(../images/blue-gold-dust-background.jpg);
	background-color: $blue;
 	background-repeat:no-repeat;
 	background-position:center;
 	background-size:cover;
 	color:$white;
 	padding:8px 0;
 	position: relative;
 	z-index: 2;
 	text-align:center;

 	@include respond-to(small){
 		text-align:left;
 	}

 	#logo-mobile{
 		display: inline-block;
 		margin:10px 0 20px;

 		img{

 		}
 	}

 	p{
 		color:$white;
 		font-size:.9rem;

 		@include respond-to(small) {
 			font-size:.75rem;
 		}

 		@include respond-to(medium) {
 			font-size:1rem;
 		}
 	}

 	.region-top-bar-left{
 		margin:0 25px;
 		font-weight:600;
 		padding:8px 0;

 		@include respond-to(xsmall){
 			margin:0px;
 		}

 		a.phone,
 		span.phone{
 			display: block;
 			color:$white;
 			text-decoration:none;

 			@include respond-to(small){
 				display: inline;
 				padding-left:8px;
 				margin-left:9px;
 				position:relative;

 				&:before{
 					content:'';
 					position: absolute;
 					left:-1px;
 					top:50%;
 					transform: translateY(-50%);
 					height:15px;
 					width:1px;
 					background-color: rgba(255,255,255,.7);
 				}
 			}
 		}
 		a.phone{
 			transition:all .2s;
 			&:hover{
 				color:$gold;
 			}
 		}
 	}

 	.row{
 		@include display-flex();
 		align-items: center;

 		.block{
 			margin-bottom:0px;

 			p{
 				margin:0px;
 			}
 		}
 	}


	#google_translate_element{
		width:200px;
		margin:0 auto;

		@include respond-to(small) {
			width:150px;
		}

		@include respond-to(medium) {
			width:200px;
		}

		.goog-te-gadget{
			font-size: 0px !important;
		}
		a.goog-logo-link{
			display:none;
			color:$white;
		}
		select{
			-webkit-appearance: none;
			-moz-appearance: none;
			appearance: none;
			width:100%;
			margin:0px;
			-webkit-border-radius: 0px;
			-moz-border-radius: 0px;
			border-radius: 0px;
			outline:none;
			padding:4px 10px 4px 8px;
			color:#a6a6a6;
			border:1px solid #d8d5c2;

			-moz-box-shadow:    inset 0 0 4px #edece5;
			 -webkit-box-shadow: inset 0 0 4px #edece5;
			 box-shadow:         inset 0 0 4px #edece5;

			background:$white url(../images/small-arrow-red.jpg) no-repeat right 4px top 6px;

		}
	}

	.region-top-bar-right{
		display: flex;
		justify-content: center;
		align-items: center;
		margin:10px 0px 5px;
		flex-wrap: wrap;

		@include respond-to(small) {
			margin:0px;
			margin:0px 0 0px 25px;
			justify-content: flex-end;
		}
	}

	//social media icons
	#block-menu-menu-social-menu{
		@include listNoStyle();
		display:flex;
		justify-content: center;
		align-items: center;
		margin:16px 0 0 0;
		width:100%;

		@include respond-to(small){
			width:inherit;
			margin:0;
		}

		@include respond-to(medium) {
			margin:0;
		}

	 	ul.menu{
	 		display: flex;
	 		margin:0px;
	 		align-items: center;
	 		padding:0px;

	 		@include respond-to(small){
	 			margin:0 0 0 10px;
	 		}

			li{
				padding:0 2px;

				@include respond-to(medium) {
					padding:0px 3px;
				}

				@include respond-to(medium) {
					padding:0px 5px;
				}

				&:last-child{
					padding-right:0px;
				}
				a{
					text-decoration:none;
					display:block;
					text-indent:-999px;
					color:rgba(255,255,255,.6);
					position:relative;
					transition:all .2s ease-in-out;
					width:26px;
					height:26px;
					overflow:hidden;
					background-color: $white;
					border-radius: 200%;

					@include respond-to(medium){
						width:36px;
						height:36px;
					}

					&:before{
						content:'';
						font-family: inherit;
						display:block;
						text-indent:0;
						position:absolute;
						top:50%;
						left:50%;
						font-size: 1.8rem;
						transform:translate(-50%, -50%);
						background-size:20px 20px;
						width:20px;
						height:20px;

						@include respond-to(medium) {
							font-size: 2.5rem;
							background-size:30px 30px;
							width:30px;
							height:30px;
						}
					}

					&:hover,
					&:focus{
						transform:scale(1.2);
						outline:none;
					}

					&.facebook{
						&:before{
							background-image: url(../images/svg/facebook-icon.svg);
						}
					}
					&.pinterest{
						&:before{
							background-image: url(../images/svg/pinterest-icon.svg);
						}
					}
					&.instagram{
						&:before{
							background-image: url(../images/svg/instagram-icon-v2.svg);
						}
					}
					&.youtube{ 
						&:before{
							background-image: url(../images/svg/youtube-icon.svg);
						}
					}
					&.tiktok{ 
						&:before{
							background-image: url(../images/svg/tiktok-icon.svg);
						}
					}
				}
			}
		}
	}
}
/**
 *
 * Top Section
 *
 */
 #top-wrapper{
 	position: relative;
 	width:100%;

 	.not-front &{
 		#top-logo-nav-wrapper{
 			@include respond-to(medium){
 				background-color: transparent;
 			}
 		}
 		#top-banner-background{
 			background-position:top center;

			@include respond-to(medium){
				background-image:url(../images/clouds-background.jpg);

				.overlay{
					height:100%;
					width:100%;

					&:after{
						content:'';
						display: block;
						position: absolute;
						z-index: 1;
						width:100%;
						height:100%;
						top:0;
						right:0;
						left: 0;
						bottom:0;
						@include css-gradient-two-vertical(rgba(32,59,94,0),00%, rgba(32,59,94,.6), 100%);
					}
				}
			}
 		}
 	}

 	@include respond-to(medium) {
 		height:310px;
 	}

 	#banner-page-title{
 		text-align:center;
 		position:relative;
 		padding:20px;
 		text-shadow:$dark-blue 0 0 30px;
 		background-color:darken($dark-blue,3%);
 		transition:all .4s;

 		> .container{
 			@include respond-to(medium){
 				position:relative;
 			}
 		}

 		@include respond-to(medium) {
 			background-color:transparent;
 			padding:20px 20px 0;
 		}

 		h1.title{
 			color:$white;
 			line-height: 1.2em;
 		}
 	}

 	#search-popup{
 		position:absolute;
 		z-index: -1;
 		top:0px;
 		left:0px;
 		right:0px;
 		bottom:0px;
 		pointer-events: none;

 		.close-popup{
 			position: absolute;
 			z-index: 100;
 			top:10px;
 			right:10px;
 			width:30px;
 			height:30px;
 			display: block;
 			transition:all .3s ease-in-out;

 			@include respond-to(small){
 				top:40px;
 				right:40px;
 				width:40px;
 				height:40px;
 			}

 			&:before,
 			&:after{
 				content:'';
 				position:absolute;
 				top:50%;
 				left:50%;
 				background-color: $white;
 				width:3px;
 				height:30px;
 				transition:all .2s ease-in-out;

 				@include respond-to(small){
 					width:4px;
 					height:40px;
 				}
 			}

 			&:before{
 				@include vendor-prefix('transform', 'translate(-50%, -50%) rotate(45deg)');
 			}
 			&:after{
 				@include vendor-prefix('transform', 'translate(-50%, -50%) rotate(-45deg)');
 			}

 			&:hover{
 				@include vendor-prefix('transform', 'scale(1.3)');

 				&:before,
 				&:after{
 					background-color: $gold;
 				}
 			}
 		}
 	}

 	&.popup-overlay{

	 	#search-popup{
	 		z-index:1000;
	 		pointer-events:auto;
	 	}

 		#top-logo-nav-wrapper{
 			transition:all .5s ease-in-out;
 			border-color:$blue;

 			@include respond-to(medium) {
 				top:-100%;

 				.overlay{
 					background:transparent;
 				}
 			}
 		}

		.overlay{
			position:absolute;
	 		z-index: 1;
	 		top:0px;
	 		left:0px;
	 		right:0px;
	 		bottom:0px;
	 		@include hex_to_rgba($blue,.95,'background');
	 		transition:all .5s ease-in-out;
		}

 		.search-forms-wrapper{
 			@include opacity(1,100);
 			transition:all .5s ease-in-out;
 		}

 		#banner-page-title{
 			@include respond-to(medium){
 				.overlay{
	 				display: none;
	 			}
 			}

 			@include respond-to(medium){
 				@include opacity(0,0);
 			}
 		}
 	}

 	.search-forms-wrapper{
 		position:absolute;
 		top:40%;
 		left:3%;
 		width:94%;
 		text-align:center;
 		display: block;
 		transform:translateY(-50%);
 		@include opacity(0,0);

 		@include respond-to(small){
 			width:100%;
 			left:0;
 		}

 		h2.search-title{
 			color:$white;
 			margin:0 0 1em;
 			font-size:1.3rem;

 			@include respond-to(small){
 				font-size:1.6rem;
 			}

 			@include respond-to(medium) {
 				font-size:2rem;
 			}
 		}

 		#search-form-type{
 			margin:20px 0;
 			a.option{
 				display: inline-block;
 				color:#becbdd;
 				margin:0 10px;
 				text-decoration: none;
 				font-size: 1rem;
 				position:relative;
 				padding:0 0 0 30px;
 				line-height: 1.2em;
 				transition:all .3s;

 				@include respond-to(small){
 					margin:0 20px;
 					font-size: 1.1rem;
 				}

 				&:before{
 					content:'';
 					width:18px;
 					height:18px;
 					position:absolute;
 					top:0px;
 					left:0px;
 					border-radius: 4px;
 					background-color: #becbdd;
 					transition:all .3s;

 					@include respond-to(small){
 						width:20px;
 						height:20px;
 					}
 				}

 				&:after{
 					content:'\f00c';
 					font-family:$fontawesome;
 					color:$red;
 					position: absolute;
 					top: 1px;
 					left:2px;
 					font-size:14px;
 					font-weight:700;
 					@include opacity(0,0);
 					transition:all .3s;

 					@include respond-to(small){
 						font-size:16px;
 					}
 				}

 				&:hover{
 					color:$white;

 					&:before{
						background-color:$white;
 					}
 				}

 				&.active{
 					color:$white;
 					pointer-events: none;

 					&:before{
 						background-color: $white;
 					}

 					&:after{
 						@include opacity(1,100);
 					}
 				}
 			}
 		}
 		#all-forms-container{
 			margin:40px 0 0;

 			.form-wrap{
 				display: none;

 				&.active{
 					display: block;
 				}

 				&:not(#website-search-form){
					form{
						display:flex;
						justify-content:center;
					}
 				}

 				form{
 					margin:0px;

	 				input[type="text"]{
	 					padding:0 10px;
	 					font-size:1.5rem;
	 					border:0px;
	 					border-radius:4px 0 0 4px;
	 					width:60%;
	 					appearance:none;
	 					height:40px;

	 					@include respond-to(small){
	 						width:400px;
	 						height:60px;
	 						padding:0 20px;
	 					}

	 					@include placeholder() {
							color:#dedede;
	 					}
	 				}

	 				button{
	 					appearance:none;
	 					background-color: $red;
	 					border:0px;
	 					border-radius: 0 4px 4px 0;
	 					padding:0px 30px;
	 					color:$white;
	 					font-size:1.8em;
						outline:none;

	 					&:hover,
	 					&:focus{
	 						background-color: lighten($red,10%);
	 					}
	 				}

 				}

 				&#website-search-form{
					form{
						.form-wrapper{
							display: flex;
							justify-content: center;

							.form-item{
								margin:0px;
							}
						}
					}
 				}
 			}
 		}
 	}

 	#top-banner-background{
	 	background-image:url(../images/full-library-photo.jpg);
	 	background-repeat:no-repeat;
	 	background-position:center center;
	 	background-size:cover;
	 	position:relative;
	 	top:0px;
	 	left:0px;
	 	overflow: hidden;
	 	z-index: -1;
	 	width:100%;
	 	height:200px;

	 	@include respond-to(xsmall){
	 		height:300px;
	 	}

	 	@include respond-to(small) {
	 		height:400px;
	 	}

	 	@include respond-to(medium) {
	 		position:absolute;
	 		height:100%;
	 	}

	 	&:before,
		&:after{
			content: "";
			position: absolute;
			top:100%;  
			background-color: $white;
			padding-bottom:10%;
			width:55%;
			z-index:2;
		}

	 	&:before{ 
			left:-5%;
			transform-origin:top right;
			transform: rotate(5deg);
		}

		&:after{ 
			right:-5%;
			transform-origin:top left;
			transform: rotate(-5deg);
		}

		#top-banner-inner{
			&:before,
			&:after{
				content: "";
				position: absolute;
				top:98%;  
				background-color: rgba(255,255,255,.6);
				width:55%;
				height:2px;
				z-index:1;

				@include respond-to(medium){
					height:3px;
				}
			}

			&:before{
				left:-5%;
				transform-origin:bottom right;
				transform: rotate(5deg) skewX(5deg);
			}

			&:after{ 
				right:-5%;
				transform-origin:bottom left;
				transform: rotate(-5deg) skewX(-5deg);
			}
		}
 	}

 	.front &{
		@include respond-to(medium){
			height:840px;
		}

		#top-banner-background{
			&:before{ 
				transform: rotate(10deg);
			}

			&:after{ 
				transform: rotate(-10deg);
			}

			#top-banner-inner{
				&:before,
				&:after{
					top:99%;  
				}

				&:before{
					transform: rotate(10deg) skewX(10deg);
				}

				&:after{ 
					transform: rotate(-10deg) skewX(-10deg);
				}
			}
		}

		#banner-down-arrow{
		    position: absolute;
		    z-index: 10;
		    display: inline-block;
		    bottom: 30px;
		    left: 50%;
		    transform:translateX(-50%);
		    font-size: 3rem;
		    color:#fff;
		    @include opacity(.5,50);
        	@include animation('arrow-down 1.2s ease-in-out 5s infinite');
		}
 	}

	#top-logo-nav-wrapper{
		border-bottom:1px solid rgba(255,255,255,.2);
		padding:15px 0 0 0;
		background-color: $dark-blue;
		top:0px;
		position: relative;
		transition:all .5s ease-in-out;

		@include respond-to(medium){
			z-index: 1;
			background-color: rgba(89,162,191,.3);
			border-bottom:1px solid rgba(255,255,255,.5);
		}

		a#logo{
			padding:0 0 20px;
			display: block;
			width:100%;
			text-align: center;

			img{
				width:50%;
				height: auto;
			}

			@include respond-to(small){
				text-align: left;
				width: auto;

				img{
					width:70%;
				}
			}

			@include respond-to(medium){
				img{
					width:100%;
				}
			}
		}
	}
 }

.header-right-wrapper{
	margin:0 0 10px;

	@include respond-to(small){
		@include display-flex();
		height:100%;
		flex-direction: column;
		justify-content: center;
		position: sticky;
		top:0px;
		align-items:flex-end;
	}

	@include respond-to(medium){
		position: static;
		justify-content: flex-end;
	}

	.region-header-right{
		margin:0px;
		transition: all .2s;
		@include display-flex();
		justify-content: center;

		form{
			text-align:center;

			@include respond-to(small){
				text-align:left;
			}
		}

		> div{
			margin-bottom:0px;

			@include respond-to(small){
				margin-bottom:1em;
			}
		}

		@include respond-to(medium){
			margin:0 0 20px;
			justify-content: flex-end;
		}

		.secondary-menu{
			display: none;

			@include respond-to(medium){
				display: block;
			}
		}

		#block-menu-secondary-menu,
		#block-menu-menu-secondary-search-menu,
		.secondary-menu{
			margin:0 0 10px;

			@include respond-to(medium){
				margin:0px;
			}

			ul.menu{
				@include listNoStyle();
				@include display-flex();
				justify-content: center;

				> li{
					margin:0 4px;

					> a{
						background-color: $light-blue;
						display: inline-block;
		 				color:$white;
		 				padding:4px 12px;
		 				border-radius: 4px;
		 				border:1px solid $blue;
		 				transition:all .2s ease-in-out;
		 				text-decoration: none;
		 				line-height: 1.5em;
		 				font-weight:600;
		 				font-size:.9em;
		 				text-align:center;

		 				@include respond-to(medium){
		 					background-color: $blue;
		 					border:1px solid $dark-blue;
		 				}

		 				@include respond-to(large){
		 					font-size:1em;
		 				}

		 				&:hover{
		 					background-color: $dark-blue;
		 				}

		 				&.search-popup{
		 					padding-right:35px;
		 					position:relative;

		 					&:after{
		 						content:'\f002';
		 						font-family: 'FontAwesome';
		 						position:absolute;
		 						top:48%;
		 						right:10px;
		 						@include vendor-prefix('transform', 'translateY(-50%)');
		 					}
		 				}
					}
					&.sfHover{
						> a{
							background-color: $dark-blue;
						}
					}
					&.red{
						a{
							background-color: $red;
							border:1px solid $dark-red;

							&:hover,
							&:focus{
		 						background-color: $dark-red;
	 						}
						}
					}
					&.gold{
						a{
							background-color: $gold;
							color:darken($dark-gold,20%);
							border:1px solid $medium-gold;

							&:hover{
								background-color: lighten($medium-gold,5%);
								border-color:darken($medium-gold,10%);
							}
						}
					}
					&.space-right{
						@include respond-to('medium') {
							margin-right:50px;
						}
					}
					&.space-left{
						@include respond-to('medium') {
							margin-right:50px;
						}
					}
				}
			}
		}
		.secondary-menu{
			ul.menu{
				> li{
					&.menuparent{
						> a{
							position:relative;
							padding:4px 30px 4px 12px;

							&:after{
								content:"\f0d7";
								position:absolute;
								font-size:16px;
								font-family: FontAwesome;
								top:50%;
								right:10px;
								transform:translateY(-50%);
							}
						}
					}

					&.red{
						background:$red;
					}

					> ul{
						top:90%;
					}

					ul{
						background:$dark-blue;
						border-radius: 4px 0 4px 4px;
				        padding:10px 0px;
				        border:1px solid $blue;
				        border-top:0px;
				        //width:auto !important;
				        left:0px;  
				        z-index:10001;
				        position: absolute;
				        right:0px;
				        left:auto;  
				        width:auto !important;
				      	@include vendor-prefix('box-shadow','0px 8px 25px -12px rgba(0,0,0,0.52)');

				        li{
				        	border-bottom:1px solid rgba(255,255,255,.3);
				        	padding:0 10px;
				        	display: block;

				        	a{
				        		padding:10px;
				        		display: block;
			        		 	color:#fff;
			        		 	font-weight:400;
					            margin:0px;
					            display:block;
					            float:none;
					            clear:both;
					            position:static;
					            width:auto;
					            line-height:1em;
					            white-space: nowrap;
					            font-size:.9rem;

					            @include respond-to(large){
					            	font-size:1rem;
					            }

					            &.nolink {
					              //color:#6a696a!important;
					            }

					            // &[target="_blank"]{
					            // 	&:after{
						           //    	border:0px;
						           //    	content:'\f14c';
						           //    	font-family: 'FontAwesome';
						           //    	line-height: 1em;
						           //    	font-size: 11px;
						           //    	color:#fff;
						           //    	position:absolute;
						           //    	top:50%;
						           //    	transform:translateY(-50%);
						           //    	right:6px;
						           //    	transition:all .2s;
					            //   	}
					            // }
				        	}

				        	&.menuparent{
				        		> a{
				        			
		        			        &:after{
						            	content:'';
						            	width:0;
						            	height:0;
						            	position:absolute;
						            	top:50%;
						            	left:7px;
						            	transform:translateY(-50%);
						            	border-style: solid;
						            	border-width: 6px 7px 6px 0px;
										border-color: transparent #fff transparent;
						            }
				        		}
				        	}

			        	 	&:hover,
		    				&.sfHover{
		    					> a{
		    						color:$gold;
		    						text-decoration:none;

		    						// &[target="_blank"]{
						      //       	&:after{
						      //       		color:$gold;
						      //       	}
						      //       }
		    					}

		    					&.menuparent{
		    						> a{
		        			        	&:after{
		        			        		border-color: transparent $gold transparent;
		        			        	}
		        			        }
		    					}

		    					li{
		    						ul{

										li{
											&:hover,
											&.sfHover{
												ul{
													top:0px;
													right:0;
													left:auto;
													margin-right:100%;
												}
											}
										}
		    						}
		    					}
		    				}

				        	&:last-child{
				        		border-bottom:0px;
				        	}

				        	ul{
				            	border-radius: 0px 4px 4px 4px;
				            	margin-right:100%;
				            	right:0px;
				            	left:auto !important;
				            }
				        }
					}
				}
			}
		}
		.paypal_btn{
			background-color: $gold;
			display: inline-block;
			color:darken($dark-gold,20%);
			padding:4px 12px;
			margin:0 0 0 4px;
			border-radius: 4px;
			border:1px solid $medium-gold;
			transition:all .2s ease-in-out;
			text-decoration: none;
			line-height: 1.5em;
			font-weight:600;
			font-size:.9em;

			@include respond-to(large){
				font-size:1em;
			}

			&:hover{
				background-color: lighten($medium-gold,5%);
				border-color:darken($medium-gold,10%);
			}
		}
	}
	.region-main-menu{
		align-self:flex-end;
	}
}


/*********************
SITE DEFAULTS
*********************/
h1.title, /* The title of the page */ h2.title,
  /* Block title or the title of a piece of content when it is given in a list of content */
  h3.title /* Comment title */ {
  margin: 0;
  color:$medium-gray;
  line-height: 1.1em;
}

h1.title, 
h1{
	line-height:1.2em;
	font-size:1.2em;

	@include respond-to(small){
		font-size:1.6em;
	}
	@include respond-to(medium){
		line-height: 1.5em;
		font-size:2em;
	}
}

.h1, .h2, .h3, h1, h2, h3{
	font-weight:700;
}

/*********************
BODY CONTENT
*********************/
body.page-staff-events-calendar{
	&.color-scheme-gray,
	&.color-scheme-sage{
		background:$white !important;
		
		#body-wrapper{

			#page-wrapper{
				margin:0px;
				#page{
					border:0px;
				}
			}
		}
	}

	#body-wrapper{	

		@include respond-to(medium){
			padding:0px;
		}
	}
}

/*********************
NAVIGATION
*********************/

header{

	.region-main-menu{
		display:flex;
		justify-content: flex-end;
	}

	.region-main-menu{
	  .block-superfish{
	  	margin:0;

	  	.sf-menu li:hover, .sf-menu li.sfHover, .sf-menu a:focus, .sf-menu a:hover, .sf-menu a:active{
		  background:transparent none;
		}

		  h2.title{
		    display: none;
		  }
		  
		  #superfish-1,
		  > .sf-menu:not(.sf-accordion),
		  #main-menu {
		    margin:0;
		    padding: 0;
		    position:relative;

		    > li{
		    	position:relative;
		    	padding:0 5px 30px;

		    	@include respond-to(large){
		    		padding:0 10px 30px;
		    	}

				&:before{
					content:'';
					position:absolute;
					top:1px;
					right:-3px;
					transition:all .2s ease-in-out;
					width:6px;
					height:6px;
					background-color: $white;
					border-radius: 200%;
				}

		    	&:first-child{
		    		padding-left:0px;
		    	}

		    	&:last-child{
		    		padding-right:0px;

		    		&:before{
		    			display: none;
		    		}
		    	}

		    	&.mobile-only{
		    		display: block;

		    		@include respond-to(medium){
		    			display: none;
		    		}
		    	}

				&:after{
					content:'';
					position:absolute;
					bottom:-1px;
					left:50%;
					@include vendor-prefix('transform', 'translateX(-50%)');
					transition:all .2s ease-in-out;
					@include opacity(0,0);
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 0 20px 0px 20px;
					border-color: transparent transparent rgba(255,255,255,.9) transparent;
				}

		    	> a{
		    		position:relative;
		    		z-index: 10;
		    		color:$white;
		    		padding:0 5px;
		    		display: inline-block;
		    	}
		    	&.active-trail{
		    		a{
		    			&:after{
							@include opacity(1.0,100);
		    			}
		    		}
		    	}
				
				&:hover,
		    	&.sfHover{
		    		> a{
		    			color:$gold;
		    		}
					&:after{
						transition:all .2s ease-in-out .2s;
						@include opacity(1,100);
						border-width: 0 20px 10px 20px;
					}
		    	}
		    }

		    > li.sfHover,
		    > li:hover{
		    	ul{
		    		top:100%;
		    	}
		    }

		    > li > a:hover,
		    > li.sfHover > a{			
			 	&:after{
					@include opacity(1,100);
				}			
		    }

		    li:hover,
		    li.sfHover{
		    	cursor:pointer;
		    }

		    li:hover,
		    li.sfHover,
		    li{
		    	li{
		    		ul{
						right:0;
						left:auto;
						margin-right:100%;
						top:-9999px;
						border-top:0 none;
		    		}
		    	}
		    }

			li{
				list-style: none;
				list-style-image: none;
				margin: 0;
				background:transparent none;
				border:0 none;
				/** border-left: 1px solid #6f6e6f; **/
				position: relative;

				&.last{
					border-right: none;
				}

				a{
					color: #fff;
					text-decoration: none;
					font-size:.9rem;
					border:0 none;
					margin-top:0px;
					position:relative;
					top:-5px;
					z-index:10;
					-webkit-font-smoothing: antialiased;
	    		 	font-weight:700;
	    		 	transition:all .2s ease-in-out;

					@include respond-to(large){
						font-size: 1.125em;
					}

					// &:hover{
					// 	z-index:10;
	    // 			}
				}

				&.sfHoverm a,
				a:hover{
					color:$gold;
				}

				li{
					&.sfHover,
					&:hover{
						ul{
							top:0;
						}
					}

					li{

					}
				}


				ul{
					background:rgba(255,255,255,.9);
					border-radius: 0 0 4px 4px;
			        padding:10px 0px;
			        width:auto !important;
			        right:-1px;  
			        z-index:10001;
			        position: absolute;
			        left:auto;  
			      	@include vendor-prefix('box-shadow','0px 8px 25px -12px rgba(0,0,0,0.52)');

			        li{
			        	border-bottom:1px solid lighten($light-gray,20%);
			        	padding:0 10px;

			        	a{
			        		padding:10px 10px;
			        		display: block;
		        		 	color:$dark-gray;
		        		 	font-weight:400;
				            margin:0px;
				            display:block;
				            float:none;
				            clear:both;
				            position:static;
				            width:auto;
				            line-height:1em;
				            white-space: nowrap;
				            font-size:.9rem;

				            @include respond-to(large){
				            	font-size:1rem;
				            }

				            &.nolink {
				              //color:#6a696a!important;
				            }

				            // &[target="_blank"]{
				            // 	&:after{
					           //    	border:0px;
					           //    	content:'\f14c';
					           //    	font-family: 'FontAwesome';
					           //    	line-height: 1em;
					           //    	font-size: .8em;
					           //    	color:rgba(255,255,255,.3);
					           //    	top:35%;
					           //    	left:4px;
				            //   	}
				            // }
			        	}

			        	&.menuparent{
			        		> a{
			        			
	        			        &:after{
					            	content:'';
					            	width:0;
					            	height:0;
					            	position:absolute;
					            	top:50%;
					            	left:7px;
					            	transform:translateY(-50%);
					            	border-style: solid;
					            	border-width: 6px 7px 6px 0px;
									border-color: transparent $dark-gray transparent;
					            }
			        		}
			        	}

		        	 	&:hover,
	    				&.sfHover{
	    					> a{
	    						color:$red;
	    					}

	    					li{
	    						ul{

									li{
										&:hover,
										&.sfHover{
											ul{
												top:0px;
												right:0;
												left:auto;
												margin-right:100%;
											}
										}
									}
	    						}
	    					}
	    				}

			        	&:last-child{
			        		border-bottom:0px;
			        	}

			        	ul{
			            	border-radius: 0px 4px 4px 4px;
			            	margin-right:100%;
			            	right:0px;
			            	left:auto !important;
			            }
			        }
				}

				&.sf-item-1,
	  			&.sf-item-2,
	      		&.sf-item-3{
	  				&.sf-depth-1 ul{
	  					left:-1px;  
			        	right:auto;

			        	li{
			        		&.menuparent{
				        		> a{
		        			        &:after{
		        			        	left:auto;
						            	right:7px;
						            	border-width: 6px 0 6px 7px;
										border-color: transparent transparent transparent $dark-gray;
						            }
				        		}
				        	}

				        	ul{
				        		left:100% !important;
				        	}
			        	}
	  				}   
		      	}
			}
	  	}


		  	#superfish-1-accordion{
		  		top:60px !important;
		  		left:0px !important;
		  		z-index: 1001;
		  		position:fixed;

		  		li{
		  			a{
		  				top:0px;
		  				float:none;
		  				color:$dark-blue;
		  				padding:8px 10px;
		  				font-size:1.2rem;
		  				line-height: 1.2em;
		  				text-decoration:none;
		  				border-bottom:1px solid $light-blue;
		  				@include hex_to_rgba($light-blue,.3,'border');

		  				&.active,
		  				&:hover{
		  					background-color: $blue;
		  					@include css-gradient-two-horizontal($dark-blue,0%, $blue, 100%);
		  					color:$white;
		  					padding:6px 10px;
		  					left:0px;
		  					margin-right: 0px;
		  				}
		  			}
		  			&.menuparent{
		  				> a{
		  					position:relative;
		  					padding-right:20px;

		  					&:after{
		  						font-family:'FontAwesome';
		  						content:'\f107';
		  						position:absolute;
		  						top:50%;
		  						right:10px;
		  						transform:translateY(-50%);
		  						line-height: 1em;
		  						transition:all .2s ease-in-out;
		  					}
		  				}
		  				&.sf-expanded{
		  					> a{
		  						@include css-gradient-two-horizontal($dark-blue,0%, $blue, 100%);
		  						color:$white;

		  						&:after{
			  						transform:rotate(180deg);
			  						top:30%;
			  					}
		  					}
		  				}
		  			}
		  			&.sf-expanded{
		  				> a{
		  					//@include hex_to_rgba(darken($blue,10%),.7,'background');
		  				}
		  				ul{
		  					li{

		  						a{
		  							@include css-gradient-two-horizontal($blue,0%, lighten($blue,10%), 100%);
		  							padding-left:30px;
		  							font-size: 1rem;
		  							color:$white;
		  							@include hex_to_rgba($white,.2,'border');
		  						}
		  						ul{
		  							li{
		  								a{
		  									background: $white;
		  									color:$dark-blue;
		  									padding-left:50px;
		  									font-size:.9rem;
		  									@include hex_to_rgba($dark-blue,.3,'border');
		  								}
		  								ul{
				  							li{
				  								a{
				  									color:$dark-gold;
				  									@include hex_to_rgba($gold,.7,'background');
				  									padding-left:70px;
				  								}
				  							}
				  						}
		  							}
		  						}
		  					}
		  				}
		  			}
		  			> ul{
						&.sf-has-clone-parent{
							@include hex_to_rgba(darken($blue,10%),.4,'background');

							li{
								&.sf-clone-parent{
									a.nolink{
										display:none;
									}
								}
							}
						}
					}
		  		}

		  		&.sf-hidden{
				    left: -99999em !important;
		    		top: -99999em !important;
		  		}
	  	}
	  	.sf-accordion-toggle{
	  		display: none;
	  	}
	  }
	}
}


.mobile-menu-expanded{
	@include respond-to-max(medium) {

		.header-right-wrapper{
			z-index: 1002;

			.region-header-right{
				@include opacity(0,0);
				pointer-events: none;
			}
		}
		//height:100vh;
		overflow: hidden;

		&:before{
			@include opacity(1,100);
		}
	}
}


/* NEWS CSS **/

/*********************
MAIN CONTENT
*********************/
#page-wrapper{
	padding-top:20px;

	@include respond-to(medium){
		padding-top:60px;
	}

	.front &{
		padding-top:20px;

		@include respond-to(medium){
			padding-top:30px;
		}
	}

	@include respond-to(medium){
		background-color:transparent;
	}

	#page{
	}

	#logo /* Wrapping link for logo */ {
	  float: none; /* LTR */
	  margin: 10px auto;
	  padding: 0;
	  display: block;
	  text-align:center;

	  img {
		  vertical-align: bottom;
		}

	  @include respond-to(medium){
	  	float:left;
	  	margin: 10px 0 10px 15px;
	  }
	}

	#header-date-time{
		font-size:.9em;
		font-weight:400;
		margin-left:20px;
		color:lighten($medium-gray,20%);
		text-align: center;

		@include respond-to(medium){
			text-align:left;
		}
	}

	header{	
		@include respond-to(medium){
			margin:0 0 15px;
		}

		#mobile-toggle-option{
			background-color:lighten($medium-gray,25%);
			color:rgba(255,255,255,.8);
			text-align:center;
			padding:6px 10px;

			i{
				position:relative;
				top:1px;
				padding:0 0 0 4px;
				font-size:1.2em;
			}

			&.active{
				color:$white;
				background-color:$red;

				i{
					top:0px;
					left:4px;
					transform:rotate(180deg);
				}
			}

			@include respond-to(medium){
				display: none;
			}
		}

		.region-top-right{
			max-height: 0px;
			overflow: hidden;
			transition:all .2s ease-in-out;

			@include respond-to(medium){
				display: block;
				max-height:inherit;
			}

			&.active{
				max-height: 160px;
			}
			
			@include respond-to-max(medium){
				left:-15px;
				position:relative;
				width:calc(100% + 30px);
			}
			@include respond-to(medium){
				margin-right: 15px;
			}
			#block-block-9{
				background-color:$red;
				border-bottom:1px solid lighten($mediumlight-blue,12%);
				padding:8px;
				margin:0;
				height:44px !important;
				overflow: hidden;

				#google_translate_element{
					.goog-te-gadget{
						font-size: 0px !important;
					}
					a.goog-logo-link{
						display:none;
					}
					select{
						-webkit-appearance: none;
						-moz-appearance: none;
						appearance: none;
						width:100%;
						margin:0px;
						-webkit-border-radius: 0px;
						-moz-border-radius: 0px;
						border-radius: 0px;
						outline:none;
						padding:4px 10px 4px 8px;
						color:#a6a6a6;
						border:1px solid #d8d5c2;

						-moz-box-shadow:    inset 0 0 4px #edece5;
						 -webkit-box-shadow: inset 0 0 4px #edece5;
						 box-shadow:         inset 0 0 4px #edece5;

						background:$white url(../images/small-arrow-red.jpg) no-repeat right 4px top 6px;

					}
				}
			}
			.block-custom-search-blocks{
				background-color:$mediumlight-blue;
				margin:0px;
				padding:10px 10px 20px;

				@include respond-to(medium){
					padding:0px 10px 10px;
				}

				form.search-form{
					margin:0px;

					.form-item-custom-search-types{
						margin:0px;

						#edit-custom-search-types{
							margin:0;
						}

						.form-item{
							&.form-item-custom-search-types{
								display: inline-block;
								margin:4px 0 6px;
								padding:0px;
								width:50%;
								float:left;
								height:25px;
								line-height: 25px;
								text-align: center;

								&:first-child{
									display: none;	
								}
								&:nth-child(2){
									@include respond-to-max(medium){
										text-align:right;
										padding-right:20px;
									}
								}
								&:last-child{
									@include respond-to-max(medium){
										text-align:left;
										padding-left:20px;
									}
								}
								input[type="radio"]{
									display: none;
								}
								label{
									position:relative;
									display:inline-block;
									color:rgba(255,255,255,.7);

									&:after{
										content:'';
										display: block;
										position:absolute;
										top:50%;
										transform:translateY(-50%);
										left:-25px;
										width:15px;
										height:15px;
										border:2px solid lighten($mediumlight-blue,40%);
									}

									&:before{
										content:'\f00c';
										font-family: 'FontAwesome';
										display: block;
										line-height: 1em;
										position:absolute;
										top:44%;
										transform:translateY(-50%);
										left:-24px;
										z-index: 2;
										font-size: 1.4em;
										@include opacity(0,0);
										color:$white;
									}
									&:hover{
										cursor:pointer;

										&:before{
											@include respond-to(medium){
												@include opacity(1,100);
												color:rgba(255,255,255,.7);
											}
										}
									}
								}
								&.active{
									label{
										color:$white;
										&:before{
											color:rgba(255,255,255,1);
											@include opacity(1,100);
										}
									}
								}
							}
						}
					}
					.form-item-custom-search-blocks-form-1{
						margin:0;
						width:60%;
						display: inline-block;
						float: left;

						input[type="text"]{
							width: 100%;
							height:28px;
							padding:0 4px;
							border:0px;
							border-radius:0 !important;
						}
					}
					.form-actions{
						margin:0px;
						width:40%;
						display: inline-block;

						input[type="submit"]{
							width:100%;
							height:28px;
							border:0px;
							background-color:darken($blue,5%);
							color:$white;
							padding:0px;
							border-radius:0 !important;
							transition:all .2s ease-in-out;

							&:hover{
								background-color:darken($blue,10%);
							}
						}
					}
				}
			}
		}
		.region-top-middle{
		  display:block;
		  padding:0 20px;

		  	@include respond-to-max(medium){
				background:lighten($gray-background,5%);
				margin:15px 0 0;
				padding:5px 20px 0px;
				position:relative;
				left:-15px;
				width:calc(100% + 30px);
				border-top:2px solid darken($gray-background,10%);
				//border-bottom:2px solid darken($gray-background,10%);
			}

			.block-block{
				&.mobile-calendar-link{
					position:relative;
					left:-20px;
					margin:0px;
					width:calc(100% + 40px);

					a{
						background-color:$blue;
						display: block;
						color:$white;
						text-align:center;
						padding:6px 10px;

						&:hover,
						&:focus{
							text-decoration: none;
						}
					}
					p{
						margin:0px;
					}

					@include respond-to(medium){
						display:none;
					}

				}
			}

		}
	}
	#main-wrapper{
		@include respond-to(medium){

			&.with-left-sidebar,
			&.with-right-sidebar{
				position:relative;
			}

			&.with-left-sidebar{
				&:before{
					content:'';
					height:98%;
					width:1px;
					position:absolute;
					z-index: 2;
					top:0px;
					left:25.7%;
					background-color:$light-gray;
				}
			}
			&.with-right-sidebar{
				&:after{
					content:'';
					height:98%;
					width:1px;
					position:absolute;
					z-index: 2;
					top:0px;
					right:25.7%;
					background-color:$light-gray;
				}
			}
		}

		#main{
			#content-wrapper{
				#content{
					padding-bottom:30px;
					@include respond-to-max(medium){
						padding-bottom:10px;
						// margin-top:20px;
						// margin-bottom:20px;
						// padding-top:20px;
						// padding-bottom:20px;
					}
					.section{

					}
					&.col-md-9.col-md-push-3{
						.section{
							@include respond-to(medium){
								margin:0 40px 0 45px;
							}
						}
					}
					&.full-content{
						width:100%;
					}

					.breadcrumb
						/* The path to the current page in the form of a list of links */ {
						padding-bottom: 0; /* Undo system.css */
		      			padding:0 0 20px;
					    margin-bottom:0px;
					    list-style: none;
					    background-color:transparent;
					    border-radius:0px;
					    color:#919191;

					    a{
					    	text-decoration:none;
					    	transition:all .2s ease-in-out;

					    	&.nolink{
					    		color:#919191;
					    	}
					    }

					}

					article{
						font-size:16px;
						line-height: 1.3em;

						> ul.links.inline{
							display: block;
							clear:both;
							margin:10px 0px 0px;
							padding:20px 0px;
							border-top:1px solid lighten($light-gray,10%);
							border-bottom:1px solid lighten($light-gray,10%);

							li{
								&:first-child{
									margin-left:0px;
								}

								a{
									color:$red;
									text-decoration:none;
									transition:all .2s ease-in-out;

									&:hover{
										color:$dark-blue;
									}
								}
							}
						}
					}

					

				}
				#left-sidebar-container{
					@include respond-to-max(medium){

						.columns-reordered{
							background:lighten($gray-background,5%);
							padding-top:20px;
							padding-bottom:20px;
							margin-top:20px;
							margin-bottom:0px;
							border-top:2px solid darken($gray-background,10%);
							border-bottom:2px solid darken($gray-background,10%);
						}
					}
				}
				#right-sidebar-container{
					@include respond-to-max(medium){
						background-color:$mediumlight-blue;
						padding-top:20px;
						padding-bottom:15px;
						//margin:0 0 20px 0;
					}
				}
				.region-sidebar-first,
				.region-sidebar-second{
					.view-site-images{
						&.view-display-id-block_2,
						&.view-display-id-block_4{
							.views-row{
								&:nth-child(odd){
									clear:left;
								}
							}
						}
						.views-row{
							margin-bottom:10px;


							@include respond-to(medium){
								margin-bottom:15px;

								&:last-child{
									margin-bottom:2px;
								}
							}
						}
						.views-field-field-image{
							a{
								background-color:$black;

								img{
									transition:all .2s ease-in-out;
									//fix for flickering
									-webkit-perspective: 1000;
									-webkit-backface-visibility: hidden;
								}

								&:hover{
									img{
										@include opacity(.6,60);
									}
								}
							}
						}
						.views-field-image-image{

							.field-content{
								display:block;
				 				height:136px;
							}
						}
						.views-field-field-image-link{
							a{
								display:block;
								background:#3a393a;
								line-height: 1.2em;
								padding:10px 10px;
								color:#fff;
								text-decoration:none;
								font-size:.9em;
								outline:none;
								transition:all .2s ease-in-out;

								&:hover{
									background-color:$red;
								}

								  @include respond-to(medium){
								  	font-size:1.25em;
							  }
							}
						}
					} 
					.view-id-site_images{

						.slick-slider{
							display:none;
							
							@include respond-to-max(medium){
								background-color:darken($dark-gray,6%);
								padding:10px 0;
							}
							
							&.slick-initialized{
								display:block;
							}

							.slick-list{
								.slick-track{
									.views-row{
										padding:0px;
										margin:0px;
										position:relative;

										.views-field-field-image{
											img{
												width:100%;
												height:auto;
												max-width: 284px;
												margin:0 auto;
												
												@include respond-to-max(medium){
													border:1px solid rgba(255,255,255,.5);
												}
											}

										}
									}
								}
							}
						}
					}

					.block{
						.view-site-images{
							.slick-slider{
								width:100%;

								.slick-track{
									div.slick-slide{
										width:100%;

										img{
											width:100%;
											height: auto;
											margin:0 auto;
										}
									}
								}
							}
						}
					}
				}
				.region-sidebar-first{
					@include respond-to(medium){
						margin:0 0 0 15px;
					}

					img{
						width:100%;
						height:auto;
						margin:0 auto;
					}
					#block-views-site-images-block-1{
						@include respond-to-max(medium){
							left:-15px;
							position:relative;
							width:calc(100% + 30px);
						}
						.view-id-site_images{
							&.row{
								margin:0px;
							}
						}
					}

					#block-block-3{
						font-size:1.275em;
  						line-height:1.2em;
					}

					.block{
						.view-site-images{
							.views-field-field-image{
								.views-row{
									margin-bottom:15px;
								}
							}
						}
					}
				}
				.region-sidebar-second{

					@include respond-to(medium){
						margin:0 15px 0 0;
					}

					img{
						width:100%;
						height:auto;
						margin:0 auto;
					}
					.view-site-images{
						.views-row{
							margin-bottom:10px;

							@include respond-to(medium){
								margin-bottom:5px;
							}

							.views-field-field-image-link{
								a{
									@include respond-to-max(medium){
										background-color:darken($blue,10%);
									}
								}
							}
						}
					}
					#block-views-site-images-block-2{
						margin-bottom:0px;
					}

				}
			}
		}
	}

	#block-block-7,
	#block-block-7--2,
	#catalog-mobile-search{
		p{
		  margin:1em 0;
		}

		a{
			display:block;
			width:100%;
			height:45px;
			border:1px solid #f3f2ed;
			line-height:45px;
			font-size:1em;
			color:$dark-gray;
			background-color:$white;
			font-weight: normal;
			text-decoration:none;
			padding:0 10px;
			position:relative;
			box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,0.1);
			border:1px solid lighten($light-gray,20%);

			@include respond-to(large){
				font-size:1.3em;
			}
			@include respond-to(xlarge){
				font-size:1.5em;
			}

			&:before{
				content:'';
				position:absolute;
				top:50%;
				transform:translateY(-50%);
				right:9px;
				z-index: 2;
				width:26px;
				height:26px;
				border:2px solid rgba(255,255,255,.8);
				border-radius:200%;
				transition:all .2s ease-in-out;
			}

			&:after{
				content:'\f002';
				color:rgba(255,255,255,.8);
				text-align:center;
				line-height:32px;
				font-family:'FontAwesome';
				position:absolute;
				font-size:16px;
				top:50%;
				right:6px;
				padding:0px 1px 0;
				transform:translateY(-50%) scale(-1, 1);
				width:32px;
				height:32px;
				background-color:$red;
				transition:all .2s ease-in-out;
			}

			&:hover{
				&:after{
					color:rgba(255,255,255,1);
					background-color:darken($red,5%);
				}
				&:before{
					border:2px solid rgba(255,255,255,1);
				}
			}
			}
		}

		#mobile-only-search{
			display: block;
			margin:0 0 20px;

			@include respond-to(medium){
				display: none;
			}
		}
	#block-block-7--2,
	#block-views-how-do-i-block-1{
		@include respond-to-max(medium){
			display:none !important;
		}
	}

}

/**
 *
 * Bottom Section
 *
 */
#bottom-wrapper{
  	content:'';
  	width:100%;
  	height: auto !important;
  	position:relative;
	//height: 100%;
	background-image:url(../images/building-faded-gray.jpg);
	background-position: bottom center;
	background-repeat: no-repeat;
	background-size:100% auto;
	padding:20px 0px 50px 0;

	@include respond-to(medium){
		padding:0 0 150px 0;
		background-position: center;
	}

	&:after{
		content:'';
		position:absolute;
		width: 100%;
		top:0;
		right:0;
		bottom:0;
		left:0;
		display: block;
		@include css-gradient-two-vertical($white,0%, rgba(255,255,255,.7), 80%);
		z-index:0;
	}

	> .container{
		position: relative;
		z-index: 2;
	}


	#block-menu-menu-helpful-resources-links{
		margin:0px;

		@include respond-to(medium){
			margin:50px 0;
		}

		h2.block__title{
			text-align:center;
			margin:0 0 1em;
				color:$default-text;
				margin:0 0 30px;
				padding:0 0 20px;
				position: relative;

				&:after{
					content:'';
					position: absolute;
					bottom:-5px;
					left:30%;
					display: block;
					height:2px;
					width:40%;
					background-color:$default-text;
				}

				@include respond-to(medium){
					margin:0 0 50px;
				}
		}

		ul.menu{
			@include listNoStyle();
			display: flex;
			justify-content: center;

			li{
				margin:0 5px;

				@include respond-to(medium){
					margin:0 30px;
				}

				a{
					background-color: $blue;
					display: inline-block;
	 				color:$white;
	 				padding:10px 25px;
	 				border-radius: 4px;
	 				border:1px solid $dark-blue;
	 				transition:all .2s ease-in-out;
	 				text-decoration: none;
	 				font-size:1rem;
	 				line-height: 1.2em;
	 				font-weight:600;
	 				text-align: center;

	 				@include respond-to(medium){
	 					font-size:1.2rem;
	 					line-height: 1.5em;
	 				}

	 				&:hover{
	 					background-color: $dark-blue;
	 				}

	 				&.search-popup{
	 					padding-right:35px;
	 					position:relative;

	 					&:after{
	 						content:'\f002';
	 						font-family: 'FontAwesome';
	 						position:absolute;
	 						top:48%;
	 						right:10px;
	 						@include vendor-prefix('transform', 'translateY(-50%)');
	 					}
	 				}
				}

				&.gold{
					a{
						background-color: $gold;
						color:darken($dark-gold,20%);
						border:1px solid $dark-gold;

						&:hover{
	 						background-color: lighten($medium-gold,5%);
 						}
					}
				}
				&.red{
					a{
						background-color: $red;
						border:1px solid $dark-red;

						&:hover{
	 						background-color: $dark-red;
 						}
					}
				}
			}
		}
	}
	#block-views-how-do-i-block-1{
		margin:20px auto 0;

		@include respond-to(medium){
			width:50%;
			
		}
	}
	#block-views-how-do-i-block-1,
		#how-do-i-mobile-search{
			position:relative;

			&.active{
		  		.view-content .item-list{
		  			height:227px;
		  			border-top: 1px solid #a6a6a6;
		 		 	border-bottom: 1px solid #a6a6a6;
		  		}
		    }
		  
			h2.block-title{
				width:100%;
				padding: 0 40px 0 10px;
				height: 45px;
				line-height: 45px;
				font-size:1em;
				color:$dark-gray;
				font-weight: normal;
				border:1px solid lighten($light-gray,20%);
				box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,0.1);
				position:relative;
				background-color:$white;

				@include respond-to(large){
					font-size:1.3em;
				}
				@include respond-to(xlarge){
					font-size:1.5em;
				}

				&:after{
					content:'\f0d7';
					color:rgba(255,255,255,.8);
					text-align:center;
					font-size:20px;
					line-height:32px;
					font-family:'FontAwesome';
					position:absolute;
					top:50%;
					right:6px;
					transform:translateY(-50%) scale(-1, 1);
					width:32px;
					height:32px;
					background-color:$red;
					transition:all .2s ease-in-out;
				}

				&:hover{
					&:after{
						color:rgba(255,255,255,1);
						cursor:pointer;
						background-color:darken($red,5%);
					}
				}
			}
			.view-content .item-list {
			  position: absolute;
			  background: #f5f5f1;
			  border-left: 1px solid #a6a6a6;
			  border-right: 1px solid #a6a6a6;
			  width:100%;
			  height:0px;
			  overflow: auto;
			  z-index: 10;
			  transition:all .2s ease-in-out;

		  		ul{
			  	list-style: none;
				list-style-image: none;
				padding: 0;
				margin: 0;
				
				li{
					border-bottom: 1px solid #a6a6a6;
		  			line-height: 45px;

		  			a{
						padding: 0 40px 0 20px;
						display: block;
						height: 45px;
						line-height: 45px;
						font-size: 20px;
						color:$dark-gray;
						font-weight: normal;
						overflow: hidden;
						text-decoration:none;
						transition:all .2s ease-in-out;

						&:hover{
							background: #FCFCFA;
						}
		  			}
				}
		  	}

		}
	}
}

/*********************
HOME PAGE
*********************/
.views-home-news{
	ul {
		  list-style:none;
		  list-style-image:none;
		  margin:5px 0px 10px 0;
		  padding:0;
		}
}	

.mobile-white{
	@include respond-to-max(medium){
		background:$white;
	}
}



#page-wrapper,
.wrap {
  padding-bottom:0;

  @include respond-to(medium){
	//margin: 1px 180px 0px 45px;
  }
}


.view-ts-events-calendar{
	.view-content{
		.item-list{
			.date-display-single{
				.date-display-range{
					display: inline;
				}
			}
		}
	}
}

.region-sidebar-first,
.region-sidebar-second{
	.view-site-images{
		.views-field-field-image{
			a{
				display: block;
				outline:none;

				img{
					display: block;
				}
			}
		}
	}
}

.view.view-mini-calendars{
	.date-nav-wrapper{
		.date-nav{
			background-color:#a39f78;
	  		color:#fff;
	  		margin-bottom: 0px;
	  		padding:0px;

	  		.date-heading{
	  			h3{
	  				font-size:1em;

	  				a{
	  					color:#fff;
	  					text-decoration: none;
	  				}
	  			}
	  		}
	  		.pager{
	  			margin:0px;

	  			li{
	  				@include listNoStyle();
	  				display: block;
	  				background:none;
	  				position:absolute;

	  				a{
						font-size: 2.4em;
						outline:none;
						padding:0px;
						line-height: 1em;
						display: block;
						margin:0px;
						background-color:transparent;
						border:0px;
						border-radius:0;
	  				}

	  				.ajax-progress{
	  					position:absolute;
	  					top:7px;

	  					@include opacity(.6,60);
	  				}

	  				&.date-prev{
	  					margin:0 0 0 5px;

	  					.ajax-progress{
	  						right:-20px;
	  					}
	  				}
	  				&.date-next{
	  					margin:0 5px 0 0;

	  					.ajax-progress{
	  						left:-20px;
	  					}
	  				}
	  			}
	  		}
		}
	}
}


/**
 *
 * Home Panels
 *
 */
 .panels-flexible-home_page_layout{
 	.panels-flexible-row{
 		margin-bottom:20px;

 		@include respond-to(medium){
 			margin-bottom: 40px;
 		}

 		@include respond-to-max(medium){
 			.panels-flexible-region{
 				float:none !important;
 				width:100% !important;
 				margin:0 0 40px;
 			}
 		}

 		&:last-child{
 			.panels-flexible-region{
 				margin:0px;
 			}
 		}

 		&.middle{
 			border:1px solid $light-blue;
 			border-radius:10px;
 			padding:40px;
 			position:relative;
 			overflow: hidden;
 			@include css-gradient-two-vertical(#d7e7ed,0%, #bbd7e2, 80%);

 			&:before{
 				content:'';
 				display: block;
 				background-color:$red;
 				width:100%;
 				height:8px;
 				position: absolute;
 				top:0px;
 				left: 0px;
 			}

 			h2.pane-title{
				text-align:center;
				color:$dark-red;
				padding:0 0 10px;
				margin:0 0 1.2em;
				position: relative;

				&:after{
					content:'';
					position: absolute;
					bottom:-5px;
					left:30%;
					display: block;
					height:2px;
					width:40%;
					background-color:$light-red;
				}
			}
 		}

 		&.bottom{
 			border:1px solid $light-blue;
 			border-radius:10px;
 			padding:40px;
 			position:relative;
 			overflow: hidden;
 			@include css-gradient-two-vertical(#d7e7ed,0%, #bbd7e2, 80%);

 			&:before{
 				content:'';
 				display: block;
 				background-color:$light-blue;
 				width:100%;
 				height:8px;
 				position: absolute;
 				top:0px;
 				left: 0px;
 			}

 			h2.pane-title{
				text-align:center;
				color:$dark-blue;
				padding:0 0 10px;
				margin:0 0 1.2em;
				position: relative;

				&:after{
					content:'';
					position: absolute;
					bottom:-5px;
					left:30%;
					display: block;
					height:2px;
					width:40%;
					background-color:$light-blue;
				}
			}

 			.panels-flexible-region-inside{
			    @include respond-to(medium){
			    	display: flex;
	 				flex-wrap:wrap;
				    justify-content: space-between;
			    }

 				.panel-pane{
 					

 					@include respond-to(medium){
 						flex:0 0 50%;
 						padding:0 50px;

 						&.events-section-title,
 						&.events-section-full-calendar-link{
 							flex: 0 0 100%;
 							padding:0px;
 						}
 					} 					

 					&.events-section-title{
	 					
	 					h2{
	 						text-align:center;
	 						color:$dark-blue;
	 						padding:0 0 10px;
	 						margin:0 0 1.2em;
	 						position: relative;

	 						&:after{
	 							content:'';
	 							position: absolute;
	 							bottom:-5px;
	 							left:30%;
	 							display: block;
	 							height:2px;
	 							width:40%;
	 							background-color:$light-blue;
	 						}
	 					}

	 				}
	 				&.events-section-full-calendar-link{
						text-align:center;
						margin:1em 0 0;

						p{
							margin:0px;
						}

						a{
							@include cta-button();
							font-size:1rem;

							@include respond-to(medium){
								font-size:1.2rem;
							}
						}
 					}

	 				&.pane-views{
	 					//text-align:center;

	 					h2.pane-title{
	 						display: inline-block;
	 						color:$light-blue;
	 						padding:0px 0 10px 40px;
	 						position: relative;


	 						@include respond-to(medium){
	 							margin-left:10px;
	 						}

	 						&:before{
	 							content:'';
	 							color:$blue;
	 							position: absolute;
	 							top:0;
	 							left:0;
	 							font-family: $fontawesome;
	 						}
	 					}

	 					.view{
	 						text-align:left;
	 						color:#2d2d2d;
	 						line-height: 1.4em;

	 						.views-row{
								margin:0 0 1.8em;

								@include respond-to(medium){
									margin:0 10px 1.8em;
								}

		 						.views-field-title,
		 						.views-field-title-1{
		 							font-size: 1.1rem;
		 							font-weight:600;
		 							margin:0 0 5px !important;
		 							

		 							a{
		 								color:$dark-blue;
		 								transition:all .2s;
		 								text-decoration:none;
		 								display: block;

		 								&:hover{
		 									color:$red;
		 								}

		 							}
		 						}
		 						.views-field-field-registration-message{
		 							a{
		 								color:$red;
		 								text-decoration:none;

		 								&:hover{
		 									color:lighten($red,10%);
		 								}
		 							}
		 						}
	 						}
	 					}
	 				}
	 				&.pane-views-ts-events-calendar-block-4,
	 				&.events-today{
	 					&.pane-views{
	 						h2.pane-title{
	 							&:before{
	 								content:'\f736';
	 							}
	 						}
	 					}
	 				}
	 				&.pane-views-ts-events-calendar-block-3,
	 				&.events-upcoming{
	 					&.pane-views{
	 						h2.pane-title{
	 							&:before{
	 								content:'\f073';
	 							}
	 						}
	 					}
	 				}
 				}
 			}
 		}
 	}
 	.panel-pane{
 		.view-site-images{
 			border:4px solid $gold;
			padding:5px;
			margin:0px;
			border-radius: 10px;

	 		.slick-slider{
	 			display: none;
	 			width:100%;
	 			height:auto;
	 			border-radius: 6px;
	 			overflow: hidden;

	 			&.slick-initialized{
	 				display: block;

	 				button.slick-arrow{
	 					position:absolute;
	 					z-index: 10;
	 					top:50%;
	 					transform:translateY(-50%);
	 					appearance:none;
	 					background:transparent;
	 					border:0px;
	 					color:rgba(255,255,255,.6);
	 					font-size:3rem;
	 					text-shadow:rgba(0,0,0,.6) 0 0 20px;
	 					@include opacity(0,0);
	 					outline:none;

	 					&.slick-prev{
	 						left:5px;
	 					}
	 					&.slick-next{
	 						right:5px;
	 					}

	 					&:hover{
	 						color:$white;
	 					}
	 				}

	 				&:hover,
	 				&:focus{
	 					button.slick-arrow{
	 						@include opacity(1,100);
	 					}
	 				}

	 				.slick-list{
	 					.slick-track{
	 						background-color: $medium-gray;
	 						display: flex;
    						align-items: center;
    						justify-content: center;
    						text-align: center;

	 						.slick-slide{
	 						}

	 						.views-row{
	 							padding: 0px;
	 							position:relative;
	 							width:auto !important;
	 							height:auto;
	 							display: block !important;
	 							max-width: fit-content;
	 							margin:0 auto;

	 							// &:after{
	 							// 	content:'';
	 							// 	display: block;
	 							// 	position: absolute;
	 							// 	z-index: 1;
	 							// 	width:100%;
	 							// 	height:100%;
	 							// 	top:0;
	 							// 	right:0;
	 							// 	left: 0;
	 							// 	bottom:0;
	 							// 	@include css-gradient-two-vertical(rgba(0,0,0,0),60%, rgba(0,0,0,.6), 100%);
	 							// }
		 						
		 						.field-content{
		 							img{
		 								width:100%;
		 								height: auto;
		 							}
		 						}
		 						.slide-information{
									position:absolute;
									z-index: 10;
									bottom:5%;
									left:0;
									display: block;
									width:100%;
									padding:0 10px;
									text-align:center;
									color:$white;

									@include respond-to(medium){
										bottom:6%;
										padding:0 20px;
									}

									.slide-title{
										font-size:1.1rem;
										font-weight:700;
										line-height: 1.1em;
										text-shadow: 0px 0px 20px rgba(0,0,0,1);

										@include respond-to(medium){
											font-size:1.5rem;
										}
									}
									.slide-link{
										a{
											margin:10px 0 0;
											color:$white;
											background-color: $dark-blue;
											padding:8px 15px;
											border-radius: 4px;
											display:inline-block;
											text-decoration: none;
											font-size:1.1em;
											font-weight:600;
											transition:all .2s;
											box-shadow: 0px 0px 80px 10px rgba(0,0,0,.5);

											@include respond-to(medium){
												margin:20px 0 0;
												font-size:1.2em;
												padding:10px 20px;
											}

											&:hover{
												background-color:$gold;
											}
										}
									}
								}
							}
	 					}
	 				}
	 			}
	 		}
 		}
 		&.about-us-homepage-block{

 			@include respond-to(medium){
 				padding:10px 0 0 5%;
 			}

			h2{
				color:$dark-blue;
				padding:0 0 0 40px;
				margin:1em 0 .5em;
				position: relative;

				&:first-of-type{
					margin-top:0px;
				}

				&:before{
					font-family: $fontawesome;
					color:$red;
					position: absolute;
					top:2px;
					left:0px;
				}

				&.title-icon-book{
					&:before{
						content:'\f6b8';
					}
				}
				&.title-icon-clock{
					&:before{
						content:'\f017';
					}
				}
				&.title-icon-phone{
					&:before{
						content:'\f87b';
					}

					a{
						color:$dark-blue;
						text-decoration:none;
						transition: all .2s;

						&:hover{
							color:$red;
						}
					}
				}
			}

			p{
				font-size:1.2rem;
				line-height: 1.4em
			}
 		}
 	}
 }

#main{
	font-size:inherit !important;
	line-height:inherit !important;
}

form.webform-client-form{
	.form-actions{
		margin:0 0 10px;

		input[type="submit"]{
			background:lighten($blue, 10%);
			border:0px;
			color:#fff;
			padding:10px 20px;
			font-size: 1.1em;
			transition:all .2s ease-in-out;

			&:hover{
				background:$blue;
			}
		}
	}
}

a.cta-red-button{
	display:inline-block;
	background:lighten($red, 10%);
	border:0px;
	color:#fff;
	padding:10px 20px;
	font-size: 1.1em;
	text-decoration:none;
	transition:all .2s ease-in-out;
	margin:5px 0 20px;

	&:hover{
		background:$red;
	}
}


//Image Gallery Main Page
.view-node-gallery-gallery-summaries{
	.views-row{
		.gallery-item{
		    position:relative;
		    margin:2px;

		    @include respond-to(small){
		    	background: transparent none;
			    border: 1px #cccccc solid;
			    margin: 1em 0;
			    padding: 1em;
			    position:static;
		    }

			.left-section{
				
				@include respond-to(small){
					float:left;
					width:20%;
				}

				.file-image{
					a{
						display: block;
						outline: none;

						img{
							transition:all .2s ease-in-out;
							display: block;
							width:100%;
							height:auto;
						}

						&:hover{
							img{
								@include opacity(.7,70);
								outline: none;
							}
						}
					}
				}
			}
			.right-section{
				position:absolute;
				bottom:0px;
				left:0px;
				
				@include respond-to(small){
					position:static;
					float:right;
					width:75%;
				}
				@include respond-to(large){
					width:78%;
				}

				h2.item-title{
					font-size: 1em;
					line-height:1.2em;
					margin:0px;
					

					@include respond-to(small){
						border-bottom:1px solid $red;
						margin:0 0 10px;
						padding:0 0 4px;
						font-size: 1.2em;
						font-weight: 700;
					}

					a{
						text-decoration: none;
						transition:all .2s ease-in-out;

						color:$white;
						@include hex_to_rgba($red,.7,'background');
						display: block;
						padding:10px 8px;

						@include respond-to(small){
							background-color:transparent;
							color:$red;
							padding:0px;

							&:hover{
								color:$light-red;
							}
						}
					}
				}
				.image-count{
					color:$medium-gray;
					padding:0 0 3px;
					display: none;

					@include respond-to(small){
						display: block;
					}

					.count{
						font-weight: 700;
						color:#000;
					}
					
				}
				.updated-count{
					color:$blue;
					display: none;
					
					@include respond-to(small){
						display: block;
					}

					.updated{
						font-weight: 700;
					}
				}
				.description{
					display: none;
					
					@include respond-to(small){
						display: block;
					}

					p{
						margin:1em 0px;
					}
				}
			}
		}
	}
}

//Image Gallery Individual Gallery Page
.view-id-node_gallery_gallery_item_views{
	padding:0 0 20px;
	margin:0 0 20px;
	border-bottom:1px solid lighten($light-gray,10%);

	.view-content{
		width:100%;

		.views-row{

			article{
				text-align: center;
				margin:2px;

				@include respond-to(small){
					margin:5px 0;
				}

				.field{
					margin-top:0px;
				}

				.file-image{

					a{
						display: block;
						outline: none;

						img{
							transition:all .2s ease-in-out;
							width:100%;

							@include respond-to(medium){
								width:auto;
							}
						}

						&:hover{
							img{
								@include opacity(.7,70);
								outline: none;
							}
						}
					}
				}
				.gallery-item-title-container{
					h2{
						padding:4px 15px;
						line-height:1.3em;
						font-size:.9em !important;
						color:$medium-gray;
						display: none;
						font-weight: 700;

						@include respond-to(small){
							display: block;
						}
					}
				}
			}
		}
	}
}

.views-home-news{
	color:$dark-gray !important;

  p{
  	color:$dark-gray;
  	font-size:1em;
  }
}

.front .pane-custom{
	color:$dark-gray;

	div{
		color:$dark-gray;
	}
}

//change the position of the admin toolbar that slides out.
div#admin-toolbar span.admin-toggle {
  top:30px;
}
body.admin-expanded div#admin-toolbar span.admin-toggle{
  top:30px;
}

body.admin-expanded div#admin-toolbar .admin-blocks{
	top:30px !important;
}


body.admin-expanded div#admin-toolbar .admin-tab{
	height:auto !important;
}

body.admin-expanded div#admin-toolbar ul.menu li a{
	height:auto !important;
}

/*********************
EVENT DISPLAY OPTIONS MENU
*********************/
#block-menu-menu-events-display-toggle{
	ul.menu{
		text-align: center;
		margin:0 0 30px;
		padding:0px;

		li.menu__item{
			@include listNoStyle();
			margin:0 4px;

			a{
				background:$light-gray;
				color:#fff;
				display: inline-block;
				text-decoration:none;
				padding:4px 12px;
				transition:all .2s ease-in-out;
				font-size:1rem;
				border-radius: 4px;
				line-height: 1.5em;

				&:hover{
					background:$medium-gray;
				}

				&.active{
					background:$red;
					pointer-events: none;
				}
			}
		}
	}
}

/*********************
PROGRAMS AND EXHIBITS CALENDAR
*********************/
.page-programs-exhibits-calendar,
.page-events-upcoming-events{
	.breadcrumb{
		display:none;
	}
}
.page-staff-events-calendar{

	h1.page__title{
		margin:0;
		text-align: center;
		position:relative;

		&:after{
			content:'';
			height:1px;
			background-color:$red;
			width:20%;
			position:absolute;
			bottom:-6px;
			left:50%;
			transform:translateX(-50%);

			@include respond-to(medium){
				bottom:-2px;
				height:2px;
			}
		}
	}
}
.view-programs-calendar,
.view-staff-events-calendar{

	.view-header{
		margin:0 0 10px;

		@include respond-to(medium){
			margin:0 0 30px;
		}
		
		.date-nav-wrapper{
			.date-heading{
				color:$red;

				h3{
					font-size:1.1em;

					@include respond-to(small){
						font-size:1.4em;
					}

					@include respond-to(medium){
						font-size:1.7em
					}
				}
			}
			.date-nav{
				ul.pager{
					list-style-type: none;
					li{

					}
				}
			}
		}
	}
	.view-filters{
		background:$blue url(../images/diagonal-blue.jpg) repeat left bottom;
		color:#fff;
		padding:10px 0;

		form{
			.views-exposed-form{
				

				@include respond-to-max(medium){
					margin:0 40px;
				}

				label{
					display:none;
				}

				.views-exposed-widgets{
					margin:0px;
					display: flex;
					justify-content:center;
					align-items:center;
					flex-flow:column;

					@include respond-to('medium') {
						flex-flow:row;
					}

					.form-item{
						.chosen-container-multi{
							//min-width:200px;
							margin:0 20px 0 0;

							@include respond-to(small){
								min-width: 200px;
								margin:0 20px 0 0;
							}

							ul{
								&.chosen-choices{
									padding:0px 10px;
									border-radius: 4px;

									li.search-field{
										input{
											color:$blue;
											font-size:1.1em;
										}
									}
									li.search-choice{
										background:$red;
										border:0px;
										color:#fff;

										a.search-choice-close{
											background:url(../js/chosen/chosen-sprite-light_red.png) -42px 1px no-repeat;
										}
									}
								}
							}
						}
						.chosen-container{
							.chosen-choices{
								border:0px;
							}

							&#edit_event_date_value_month_chosen{
								min-width:50px;
							}
							&#edit_event_date_value_year_chosen{
								min-width:70px;
							}

							.chosen-drop{
								border:0px;

								.chosen-results{
									li{
										text-align: left;
										&.active-result{

											&.highlighted{
												background:$red;
											}
										}
									}
								}
							}
						}
						.chosen-container-single{
							margin:0 10px 0 0;
							position:relative;

							a.chosen-single{
								border:0px;
								font-size:1.1em;
								background:#fff;
								color:$blue;
								height:27px;
								line-height: 26px;

								div{
									b{
										background-position:0 4px;
									}
								}
							}
							.chosen-drop{
								.chosen-results{
									li{
										&.active-result[data-option-array-index="0"]{
												display: none;
										}
									}
								}
							}
						}
						.chosen-container-active{
							ul{
								&.chosen-choices{
									@include respond-to(medium){
										border-radius: 4px 4px 0 0;
									}
								}
							}
						}
					}

					.views-exposed-widget{
						float:none;
						padding:0px;
						display: inline-block;
						position: relative;

						@include respond-to-max(medium){
							width:100%;
							margin-bottom:2px;
							
							&.views-widget-filter-field_ts_event_date_value{
								width:100%;

								.form-item{
									margin:0px;
									width: 100%;

									.form-item{
										width:100%;
										margin-right:1%;
									}
								}
							}
						}

						&#edit-field-ts-event-age-group-tid-wrapper{
							.views-widget{
								.form-item-field-ts-event-age-group-tid{
									@include respond-to-max(medium){
										height:25px;
									}
								}
							}
						}

						select[multiple]{
							color:$medium-gray;
							@include respond-to-max(medium){
								height:25px;
								line-height:25px;
								width:100%;
								border-radius: 5px;

								option{
									padding:0px 2px;
									line-height: 25px;
								}
							}
							@include respond-to(medium){
								display:none;
							}
						}
						select{
							&.form-select{
								@include respond-to-max(medium){
									background-color:$white;
									color:$medium-gray;
									width:100%;
									margin-right:0px;
								}
							}
						}

						input[type="submit"]{
							margin:0px;
							border:0px;
							background:lighten($red, 10%);
							border:1px solid lighten($red, 30%);
							color:#fff;
							padding:0px;
							height:27px;
							width:100%;
							font-size: 1em;
							text-decoration:none;
							border-radius: 4px;
							transition:all .2s ease-in-out;

							@include respond-to(medium){
								padding:0px 20px;
								height:27px;
								font-size: 1.1em;
							}

							&:hover{
								background:$red;
							}
						}

						.ajax-progress .throbber {
					 		background-image:url(../images/ajax-loader-lightblue.gif);
					 		position:absolute;
					 		top:2px;
					 		right:-40px;
					 		width: 24px;
					 		height: 24px;
					 		padding:0px;
						}

						#edit-event-date-value{
							.form-item{
								@include respond-to-max(medium){
									margin-bottom:5px;
								}
							}

							@include respond-to('medium') {
								display: flex;
								align-items:center;
							}
						}

					}
				}
			}
		}
	}
	.view-content{
		margin:0;
		.calendar-calendar{
			.month-view,.week-view,.day-view{
				table.full{
					thead{
						tr{
							th{
								font-size:1.2em;
								padding:10px;

								@include respond-to-max(medium){
									width:14.28%;
									height:25px;
									overflow: hidden;
									padding:0px;
								}

							}
						}
					}
					tr{
						td{
							@include respond-to-max(medium){
								width:14.28%;
								height:25px !important;
								line-height:1em;
								padding:0px;
								overflow: hidden;
								position:relative;

								&.no-entry{
									.inner{
										height:25px !important;
									}
								}
							}


							&.multi-day{
								.monthview{
									height:10em;
									border:1px solid #075e7a;

									.cutoff{
										display:none;
									}

									a.event-item{
										border:0px;

										&:hover{
											border:0px;
										}
									}
									.contents{
										left:0px;
									}
								}
							}

							.inner{
								@include respond-to-max(medium){
									height:100% !important;
								}
							}

							.item{
								@include respond-to-max(medium){
										display: none;

										&:first-child{
											display: block;
											height: 100%;

										}

										.view-item{
											width:100%;
											height:100%;


											.calendar,
											.calendar div,
											.calendar span{
												height:100%;
												margin:0px;
												padding:0px;
												border-radius: 0px;

											}
											.calendar span{
												display: block;

											}

											a.event-item{
												display: block;
												height:100% !important;
												width:100% !important;
												margin-bottom:2px;
												background-image:none !important;
												background-color:$red !important;
												text-align: center;
												border:0px;
												border-radius: 0px;
												color:rgba(255,255,255,.7);
												position:relative;

												&:after{
													content:'\f14c';
													font-family: 'FontAwesome';
													font-size:1em;
													line-height: 1em;
													position:absolute;
													top:50%;
													left:50%;
													width:100%;
													transform:translate(-50%,-50%);
												}


												div{
													display:none;
												}
											}
										}
									}
							}

							.calendar{
							    margin-bottom:10px;
							    background:none;

							    .item{
							    	background:none;
							    }

							    	a.event-item{
							    		background:#b4d9e7 url(../images/diagonal-lightblue.jpg) repeat left top;
									    color:#003e60;
									    width: auto;
									    padding:0px;
									    overflow: hidden;
									    border:1px solid #075e7a;
										display: block;
										line-height: 1.2em;
										font-size: 12px;
										border-radius: 4px;



										.event-title{
											@include hex_to_rgba($blue,.7,'background');
											transition:all .2s ease-in-out;
											color:#fff;
											padding:6px;
											margin:0px;
											font-size:1.3em;
											line-height: 1.2em;
										}

										.event-info{
											padding:10px 6px;
											margin:0px;
											font-size:1.2em;
											line-height: 1.2em;
											position:relative;
											transition:all .2s ease-in-out;

											&:after{
												content:'';
												background:$red;
												position:absolute;
												top:0;
												left:0;
												width: 100%;
												height: 100%;
												@include opacity(0,0);
												transition:all .2s ease-in-out;
											}

											> div{
												margin:0 0 8px;
												position:relative;
												z-index: 1;

												&:last-child{
													margin:0px;
												}
											}

										}

										&:hover{
											border:1px solid $red;

											.event-title{
												background-color:$red;
											}

											.event-info{
												color:#fff;

												&:after{
													@include opacity(1,100);
												}
											}
										}
							    	}
							}
							&.empty{
								background:#F4F4F4 url(../images/diagonal-gray.jpg) repeat left top;
							}
							&.today{
								background:none;
								border:1px solid #ccc;
							}
						}
					}
					tr.date-box{
						td.date-box{
							.inner{
								.month.day{
									font-size:1.3em;
									a{

									}
								}
							}
							&.empty{
								background:#F4F4F4 url(../images/diagonal-gray.jpg) repeat left bottom;
							}
							&.today{
								background-color:#fff991;
								border:0px;

								.inner{
									.month.day{
									font-size:1.3em;
										a{
											color:#989a75;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
	.date-nav-wrapper{
		.date-nav{
			margin:0px;
			padding:0px;

			ul.pager{
				margin:0px;

				li{
					background:none;
					padding:0px;
					top:53px;

					@include respond-to(medium){
						top:70px;
					}

					a{
						color:#fff;
						text-indent: -9999px;
						overflow: hidden;
						display: block;
						text-align: left;
						position:relative;
						padding:0px;
						margin:0px;
						height:30px;
						width:18px;
						background-color:transparent;
						border:0px;
						border-radius:0;

						&:before{
							text-indent: 0;
							font-family:'fontAwesome';
							font-size:3em;
							color:#fff;
							position:absolute;
							top:50%;
							transform:translateY(-50%);
							left:0;
							@include opacity(.4,40);
							transition:all .2s ease-in-out;
						}

						&:hover{
							&:before{
								@include opacity(1,100);
							}
						}
					}
					&.date-prev{
						right:inherit;
						left:10px;

						a{
							&:before{
								content:'\f104';
							}
						}

						
					}
					&.date-next{
						left:inherit;
						right:10px;

						a{
							&:before{
								content:'\f105';
							}
						}
						
					}
				}
			}
		}
	}
}

/*********************
SEARCH RESULTS
*********************/
.page-search{
	.breadcrumb{
		display: none;
	}
	#content{
		> .section{
			> h2:not(.element-invisible){
				display: none;
			}
		}
	}
	ol.search-results{
		padding:0px;

		li{
			.search-snippet-info{
				padding:0px;

				p.search-snippet{
					margin:0 0 .5em;
				}
				p.search-info{
					color:$light-gray;
				}
			}
		}
	}
}	

.container-fluid{
	> .side-padding{
		
		@include respond-to(medium){
			padding:0 5%;
		}
	}
} 

/*********************
FOOTER
*********************/
#footer{
	background:$blue;

	p{
		color:$white;
	}

	#footer-top{
		padding:30px 0;

		@include respond-to(medium){
			padding:60px 0;
		}
	}

	.section {
		position:relative;

	  .region-footer{
		  color:rgba(255,255,255,.5);
		  text-align:center;	
		  font-size:.9rem;

		  @include respond-to(medium){
		  	text-align:right;
		  	font-size:1rem;
		  }

		 //  a{
		 //  	color:#898989;
  	// 		text-decoration: none;

  	// 		&:hover,
			// &:visited {
			//   color: #fff;
			//   text-decoration: underline;
			// }
		 //  }
		  p{
		  	  margin:0px 0;
		  }

		  #block-menu-menu-footer-menu{
		  	ul.menu{
				@include listNoStyle();
				display: block;
				text-align: left;

				li{
					display: block;

					a{
						text-decoration:none;
						transition:all .2s ease-in-out;
						display: block;
						color:rgba(255,255,255,.8);

						&:not(.nolink){
							&:hover{
								color:$white;
							}
						}
					}
				}
		  	}
		  	> ul.menu{
		  		@include display-flex();
		  		flex-wrap:wrap;

		  		> li{
					flex:1 1 50%;
					width:50%;
					margin-bottom:20px;
		  			position: relative;

		  			@include respond-to-max(medium){
		  				&:nth-child(odd){
							padding:0 10px 0 0;
		  				}
		  				&:nth-child(even){
							padding:0 0 0 10px;
		  				}
		  			}

		  			@include respond-to(medium){
		  				flex:1 1 0;
		  				padding:0 20px;
		  			}

		  			@include respond-to(large) {
		  				padding:0 60px;
		  			}

		  			@include respond-to(xlarge) {
		  				padding:0 80px;
		  			}
		  			@include respond-to(xxlarge) {
		  				padding:0 100px;
		  			}

		  			@include respond-to(medium){
		  				&:first-child{
			  				padding-left:0px;
			  			}

			  			&:last-child{
			  				padding-right:0px;

			  				&:after{
			  					display:none;
			  				}
			  			}
			  			&:after{
			  				content:'';
			  				display: block;
			  				position: absolute;
			  				width:1px;
			  				height:100%;
			  				top:0;
			  				right:-1px;
			  				background-color: rgba(255,255,255,.3);
			  			}
		  			}

		  			> a{
		  				color:$gold;
		  				display: block;
		  				margin:0 0 20px;
		  				font-weight:700;

		  				&:not(.nolink){
							&:hover{
								color:$medium-gold;
							}
						}
		  			}

		  			ul.menu{

		  				li{
		  					padding:0 0 8px 0;
		  					line-height: 1.2em;

		  					@include respond-to(medium){
		  						padding:0 0 12px 0;
		  					}

		  					&:last-child:not(.is-expanded){
		  						padding:0px;
		  					}
		  				}

		  				ul.menu{
		  					font-weight:300;
			  				padding:0 0 0 5%;

			  				li{
			  					padding:6px 0;
			  				}
		  				}
		  				
		  			}
		  		}
		  	}
		  }
	  }
	}
	#footer-bottom{
		background-color: $dark-blue;
		border-top:1px solid $white;
		color:$white;
		padding:10px 0;

		@include respond-to(medium){
			padding:30px 0;
		}

		.region-footer-bottom-left{
			text-align:center;

			@include respond-to(medium){
				text-align:left;
			}

			p{
				font-size: .8rem;
				line-height: 1.5em;
				font-weight:300;
				margin:0 0 .5em;

				@include respond-to(medium){
					font-size:.9rem;
					margin:0px;
				}
			}
			a{
				color:$white;
				text-decoration: none;
				transition:all .2s ease-in-out;

				&:hover{
					color:rgba(255,255,255,.6);
				}
			}
		}

		.region-footer-bottom-right{
			> .block{
				&.browser-magnify-link{
					margin:0px;
					font-family:$libre;
					font-weight:600;

					p{
						margin:0px;
						padding:0px;
						line-height: .8em;
					}
					a{
						color:$white;
						font-size:1.5rem;
						display: inline-block;
						margin:0 0 0 10px;
						text-decoration:none;
						transition: all .2s ease-in-out;

						&:hover{
							color:$gold;
						}

						span{
							padding:0 3px;
						}

						.small{
							font-family:$libre;
							font-weight:700;
						}
					}
				}
			}
		}


	/**
	 *
	 * Social Media Menu
	 *
	 */
	 #block-menu-menu-social-menu{
	 	ul.menu{
	 		@include listNoStyle();
			display:flex;
			justify-content: center;

			li{
				padding:0 3px;

				@include respond-to(medium){
					padding:0 6px;
				}

				&:last-child{
					padding-right:0px;
				}

				a{
					text-decoration:none;
					display:block;
					text-indent:-999px;
					color:rgba(255,255,255,.6);
					position:relative;
					transition:all .2s ease-in-out;
					width:40px;
					height:40px;
					overflow: hidden;

					&:before{
						font-family: 'FontAwesome';
						display:block;
						text-indent:0;
						position:absolute;
						top:56%;
						left:50%;
						font-size: 1.8rem;
						transform:translate(-50%, -50%);

						@include respond-to(medium){
							font-size: 2.5rem;
						}
					}

					&:hover{
						color:$white;
					}

					&.facebook{
						&:before{
							content:'\f082';
						}
					}
					&.twitter{
						&:before{
							content:'\f081';
						}
					}
					&.pinterest{
						&:before{
							content:'\f0d3';
						}
					}
					&.instagram{
						&:before{
							content:'\f16d';
						}
					}
					&.youtube{
						&:before{
							content:'\f167';
						}
					}
					&.tiktok{
						&:before{
							content:'\e07b';
						}
					}
				}
			}
		}	
	 }


	}
	.block{
		margin:0px;
	}
}


/**************************************
THEME OVERRIDES FOR THE STAFF CALENDAR
**************************************/
.page-staff-events-calendar{
	#body-wrapper{
		background:$white;
	}
	&.sidebar-first{
		#content-wrapper{
			background:none;
			#content{
				.section{
					padding-top:30px;
					padding-left:0px;
				}
			}
		}
	}
	#page-wrapper{
		max-width:100%;
		margin:50px auto;
	}
}

