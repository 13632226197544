//
// Initialization partial
//
// To make it easier to use all variables and mixins in any Sass file in this
// theme, each .scss file has a @import "init" declaration. And this _init.scss
// file is in charge of importing all the other partials needed for the theme.
//
// This initialization partial is organized in this way:
// - First we set any shared Sass variables.
// - Next we import Compass plug-ins (Sass mixin libraries).
// - Last we define our custom mixins for this theme.
//


// =============================================================================
// Variables
// =============================================================================

//
// Legacy IE support
//
// These variables are used by many mixins to add additional CSS to support
// specific versions of IE or specific vendor prefixes.
//
// IE6-7 don't support box-sizing: border-box. We can fix this in 1 of 3 ways:
// - Drop support for IE 6/7. :-) Set $legacy-support-for-ie6
//   and $legacy-support-for-ie7 to false.
// - (Preferred) Install the box-sizing polyfill and set the variable below to
//   the absolute path URL to the boxsizing.htc file.
//   @see https://github.com/Schepp/box-sizing-polyfill
//   $box-sizing-polyfill-path: "/path/to/boxsizing.htc";
// - Use the same CSS unit for grid and gutter width in resonsive-sidebars.scss
//   (use px for both or use % for both) and set the box-sizing variable to content-box.
//
// Zen does not require special handling for IE8 or later. But Compass uses that
// variable for a couple edge cases. We include it for completeness sake. See
// the documentation at http://compass-style.org/reference/compass/support/
// $legacy-support-for-ie6: false;
// $legacy-support-for-ie7: false;
// $legacy-support-for-ie8: true;


//
// Font faces, stacks and sizes.
//

// Compass' vertical_rhythm extension is a powerful tool to set up a vertical
// rhythm for your entire page. You can see some of its mixins and functions in
// use in the normalize.scss file.
// @see http://compass-style.org/reference/compass/typography/vertical_rhythm/

$base-font-size:   16px; // The font size set on the root html element.
$base-line-height: 24px; // This line-height determines the basic unit of vertical rhythm.

$h1-font-size: 2    * $base-font-size;
$h2-font-size: 1.5  * $base-font-size;
$h3-font-size: 1.17 * $base-font-size;
$h4-font-size: 1    * $base-font-size;
$h5-font-size: 0.83 * $base-font-size;
$h6-font-size: 0.67 * $base-font-size;

// The following font family declarations are based on the Microsoft core web
// fonts which are common fonts available on most computer systems. The DejaVu
// and Nimbus Sans fonts are commonly available on Linux systems where the MS
// fonts are less common. Tahoma and Helvetica are also widely available.
//
// A user's web browser will look at the comma-separated list and will
// attempt to use each font in turn until it finds one that is available
// on the user's computer. The final "generic" font (sans-serif, serif or
// monospace) hints at what type of font to use if the web browser doesn't
// find any of the fonts in the list.

// First, let's create some font stacks.
$times-new-roman: "Times New Roman", Times, Georgia, "DejaVu Serif", serif;
$times:           Times, "Times New Roman", Georgia, "DejaVu Serif", serif;
$georgia:         Georgia, "Times New Roman", "DejaVu Serif", serif;

$verdana:         Verdana, Tahoma, "DejaVu Sans", sans-serif;
$tahoma:          Tahoma, Verdana, "DejaVu Sans", sans-serif;
$helvetica:       Helvetica, Arial, "Nimbus Sans L", sans-serif;
$arial:           Arial, Helvetica, "Nimbus Sans L", sans-serif;

$libre:          'Libre Franklin', sans-serif;
$roboto:         'Roboto', sans-serif;
$fontawesome:    'Font Awesome 5 Pro';
$fontawesome_duotone:    'Font Awesome 5 Duotone';

// For an explanation of why "sans-serif" is at the end of this list, see
// http://meyerweb.com/eric/thoughts/2010/02/12/fixed-monospace-sizing/
$courier:         "Courier New", "DejaVu Sans Mono", monospace, sans-serif;

// Now create some variables for the font stacks we want to use on this site.
$base-font-family: $roboto; // The font family set on the html element.
$font-body:        $verdana;
$font-monospace:   $courier;


//
// Colors, etc.
//
$white: #ffffff;
$black: #000000;
$bright-red: #ce1335;
$red: #8c1329;
$dark-red: darken($red,10%);
$light-red: #b07073;
$blue: #2F4C72;
$dark-blue: #203b5e;
$mediumlight-blue: lighten($blue,10%);
$light-blue: #59a2bf;
$gold: #f2bc1a;
$medium-gold: #d89b29;
$dark-gold: #705117;
$green: #009462;
$sage: #908f70;
//$sage: #dce5db;

$light-gray: #a6a6a6;
$medium-gray: #4e4e4e;
$dark-gray: #3a393a;
$default-text: #2d2d2d;
//$gray-background: #dee1e5;
//$gray-background: #cfdce5;
$gray-background: #d6e4ee;

// The amount lists, blockquotes and comments are indented.
$indent-amount: 30px;

// The height of the navigation container.
$nav-height: 3em;

// Tab styling.
$tabs-container-bg: #fff;
$tabs-border: #bbb;


// =============================================================================
// Partials to be shared with all .scss files.
// =============================================================================

// Now we add our custom helper mixins.
@import "mixins";